import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { Button, IconButton, Tooltip } from '@mui/material'
import axios from 'axios'
import { session } from 'qurtty-js-sdk'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import styles from './index.module.css'

const InstructionPanel = ({
  setPreviewingFactsheets,
  setLoading,
  setInstructionPanelOpen,
}: any) => {
  const [downloaded, setDownloaded] = useState(false)

  const excelSampleLink = process.env.PUBLIC_URL + '/factsheet-sample.xlsx'
  const getQuote = () => {
    window.open('https://www.factsheet.hk/en/', '_blank')
  }

  const onDownloadSheet = async () => {
    setLoading(true)
    await axios({
      url: excelSampleLink,
      method: 'GET',
      responseType: 'blob',
    }).then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'factsheet-sample.xlsx')
      document.body.appendChild(link)

      link.click()
      link.remove()

      setDownloaded(true)
      setLoading(false)
    })
  }

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData()
    acceptedFiles.forEach((file) => {
      formData.append('files[]', file)
    })
    formData.append('after_upload_action', 'preview')
    formData.append('date', new Date().toString())
    setLoading(true)
    axios.post(process.env.REACT_APP_API_BASE_URL + '/fund/factsheets/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        includes: JSON.stringify({
          ...session.V3_Fund_Factsheet.INCLUDES_PARAMS,
          fund_size_currency: {},
          fund: { asset_values: {}, fundhouse: {} },
          factsheet_breakdowns: { breakdown: {} },
        }),
      },
    }).then((resp) => {
      const factsheets = resp.data['v3/fund/factsheets']
      factsheets.forEach((factsheet) => {
        factsheet.performances[0].fund_title_en = factsheet.fund_name_en
        factsheet.performances[0].fund_title_zh_hk = factsheet.fund_name_zh_hk
        factsheet.performances[0].benchmark_title_en = 'Benchmark'
        factsheet.performances[0].benchmark_title_zh_hk = '基準'
      })
      setPreviewingFactsheets(factsheets)
    }).finally(() => {
      setLoading(false)
    })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

  return (
    <div className={styles.InstructionPanel_root}>
      <div className={styles.close_btn}>
        <IconButton size='medium' onClick={() => setInstructionPanelOpen(false)}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div className={styles.title}>
        <span className={styles.sub}>automated publishing</span>
        <span className={styles.main}>demo</span>
      </div>
      <div className={`${styles.sub} ${styles.sub_title}`}>
        fund fact sheet
      </div>
      <div className={styles.steps}>
        <span>
          <Tooltip title='Download'>
            <IconButton size='large' className={`${styles.step_button} ${downloaded ? styles.success : ''}`} disabled={downloaded} onClick={onDownloadSheet}>
              <GetAppRoundedIcon />
            </IconButton>
          </Tooltip>
        </span>
        <span className={`${styles.arrow} ${downloaded ? styles.success : ''}`}>
          <svg height='35' width='60' >
            <polyline fill='none' strokeWidth='2' points='20,25 30,34 40,25' strokeLinecap='round' />
            <polyline fill='none' strokeWidth='2' points='30, 0 30,34' />
          </svg>
        </span>
        <span {...getRootProps()}>
          <input {...getInputProps()} disabled={!downloaded} />
          <Tooltip title='Upload'>
            <IconButton size='large' className={styles.step_button} disabled={!downloaded}>
              <PublishRoundedIcon />
            </IconButton>
          </Tooltip>
        </span>
      </div>
      <div className={styles.quote}>
        <Button variant='contained' size='large' onClick={getQuote}>
          get quote
        </Button>
      </div>

      <div className={styles.alert}>
        <ul>
          <li>This is A Demo Only, Not A Final Product</li>
          <li>Data Not Stored In Database</li>
          <li>Respect Your Data</li>
        </ul>
      </div>

      <div className={styles.footer}>
        <span><a href='https://shergon.com/en/automated-publishing/page' rel='noreferrer'>© 2021 SHERGON Publishing Limited</a></span>
        <span style={{ textAlign: 'end' }}>powered by <a href='http://qurtty.com/' target='_blank' rel='noreferrer'>qurtty</a></span>
      </div>

    </div>
  )
}

export default InstructionPanel
