const fakeDiv = document.createElement('div')
fakeDiv.style.position = 'absolute'
document.body.appendChild(fakeDiv)

const scaleHelper = {
  screenWidthNeedScale: (templateWidthWithUnit) => {
    fakeDiv.style.width = templateWidthWithUnit
    const rect = fakeDiv.getBoundingClientRect()
    return window.innerWidth / rect.width
  },
}

export default scaleHelper
