import { css } from '@emotion/css/macro'
import { Avatar, Badge, Button, useTheme } from '@mui/material'
import React from 'react'

const CompanySection = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return <div className={classes.root}>
    <Badge
      overlap='rectangular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      style={{ marginRight: '.8rem' }}
      badgeContent={
        <Avatar classes={{ root: `${classes.smallAvatar} ${classes.avatar}` }} src={`${process.env.PUBLIC_URL}/flag.png`} variant='rounded' />
      }
    >
      <Avatar className={classes.avatar} src={`${process.env.PUBLIC_URL}/shergon-logo-white.jpeg`} variant='rounded' />
    </Badge>
    <div className={classes.flex_col} style={{ width: '100%' }}>
      <div className={classes.first_title}>SHERGON Publish Limited</div>
      <div className={classes.flex_row}>
        <div className={classes.flex_col}>
          <div className={classes.second_title}>Automated Publishing</div>
          <div className={`${classes.info} ${classes.second_title}`}>Build Relationship</div>
        </div>
        <Button className={classes.request} href='https://shergon.com/en/automated-publishing/page' variant='outlined' size='small'>
          Request
        </Button>
      </div>
    </div>
  </div>
}

const useStyles = (theme) => ({
  root: css({
    width: '100%',
    height: '100%',
    background: 'white',
    paddingRight: '1em',
    paddingLeft: '1em',
    boxShadow: 'rgba(0,0,0,0.08) 0px 1px 8px',

    display: 'flex',
    alignItems: 'center',

    '@media screen and (max-width: 834px) ': {
      paddingTop: '1em',
    },
  }),
  flex_row: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  flex_col: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  }),
  avatar: css({
    border: '1px solid rgba(144, 144, 144, .5)',
  }),
  smallAvatar: css({
    '&&': {
      width: '.9em',
      height: '.9em',
      borderRadius: '6px',
    },
    '& img': {
      borderRadius: '5px',
    },
    right: '.3em',
    bottom: '.2em',
  }),
  first_title: css({
    fontSize: '.9rem',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, .7)',
  }),
  second_title: css({
    fontSize: '.5rem',
    fontWeight: 'lighter',
  }),
  info: css({
    color: 'rgb(247, 155, 84)',
  }),
  request: css({
    '&&': {
      color: '#008eaf',
      borderColor: '#008eaf',
      borderRadius: '20px',

      fontSize: '.2rem',
      lineHeight: 1,
      paddingRight: '5em',
      paddingLeft: '5em',
      textTransform: 'none',
    },
    '&:hover': {
      borderColor: '#008eaf !important',
    },
  }),
})

export default CompanySection
