import { ORM } from 'redux-orm'
import { } from './models/modelDefault'
import { blogModelRegister } from './models/blogModel'
import { cardModelRegister } from './models/cardModel'
import { companyModelRegister } from './models/companyModel'
import { employmentModelRegister } from './models/employmentModel'
import { eventModelRegister } from './models/eventModel'
import { exposuresModelRegister } from './models/exposuresModel'
import { friendModelRegister } from './models/friendModel'
import { registerFundModel } from './models/fundModels'
import { hkPostalModelRegister } from './models/hkPostalModel'
import { invitationModelRegister } from './models/invitationModel'
import { johnpcartyModelRegister } from './models/johnpcartyModel'
import { memberModelRegister } from './models/memberModel'
import { photoModelRegister } from './models/photoModel'
import { productModelRegister } from './models/productModel'
import { projectModelRegister } from './models/projectModel'
import { quizModelRegister } from './models/quizModel'
import { registerV5CardModels } from './models/v5CardModels'
import { registerV5ExposuresModels } from './models/v5ExposuresModels'
import { registerV5PhotoModels } from './models/v5PhotoModels'
import { registerV6ProjectModels } from './models/v6ProjectModels'
import { versionModelRegister } from './models/versionModel'

const orm = new ORM()
registerFundModel(orm)
hkPostalModelRegister(orm)
memberModelRegister(orm)
companyModelRegister(orm)
cardModelRegister(orm)
projectModelRegister(orm)
quizModelRegister(orm)
exposuresModelRegister(orm)
photoModelRegister(orm)
productModelRegister(orm)
friendModelRegister(orm)
invitationModelRegister(orm)
employmentModelRegister(orm)
versionModelRegister(orm)
johnpcartyModelRegister(orm)
blogModelRegister(orm)
eventModelRegister(orm)
registerV5PhotoModels(orm)
registerV5CardModels(orm)
registerV5ExposuresModels(orm)
registerV6ProjectModels(orm)

export {
  orm,
}
