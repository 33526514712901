import { QurttyModel } from './modelDefault'

export class V6_Project extends QurttyModel {
  static modelName = 'V6_Project'
  modelUrl = 'v6/projects'
  static fields = {
  }

  getOwner () { return this.getPolymorphicHolder('owner') }
}

export const registerV6ProjectModels = (orm) => {
  orm.register(V6_Project)
}
