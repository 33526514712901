const isRecordsEqual = (firstRecord, secondRecord) => {
  if (!firstRecord || !secondRecord) { return false }

  return firstRecord.id === secondRecord.id && firstRecord.model_class === secondRecord.model_class
}
const getClassSlashId = (attr) => {
  return `${attr.model_class}/${attr.id}`
}
export default {
  isRecordsEqual,
  getClassSlashId,
}
