import { css } from '@emotion/css/macro'
import { Button, useTheme } from '@mui/material'
import React from 'react'
import CompanySection from '../CompanySection'

const HeadBar = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <div className={classes.headBar}>
      <div style={{ flex: '1 0 auto', minWidth: '20%' }}>
        <CompanySection />
      </div>
      <div style={{ flex: '1 1 auto', width: '80%', background: 'white', boxShadow: 'rgba(0,0,0,0.08) 0px 3px 8px' }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1em' }}>
          <div className={classes.systemTitle}>
            <a><h2 style={{ display: 'inline-block', margin: '0' }}>Factsheet</h2></a>
            <span> | <a style={{ fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)' }} href='https://site.qurtty.com/c/shergon/q-project/273/'>Namecard</a></span>
          </div>
          <Button className={classes.loginBtn} variant='outlined' size='medium'>
            login
          </Button>
        </div>
      </div>
    </div>
  )
}

const useStyles = (theme) => ({
  headBar: css({
    minHeight: '4em',
    width: '100%',
    zIndex: 10,
    display: 'flex',
    gap: '.5em',
    position: 'sticky',
    top: 0,

    '@media screen and (max-width: 834px) ': {
      flexWrap: 'wrap',
      gap: '0',
    },
  }),
  loginBtn: css({
    '&&': {
      color: '#008eaf',
      borderColor: '#008eaf',

      borderRadius: '20px',
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    },
    '&:hover': {
      borderColor: '#008eaf !important',
    },
  }),
  systemTitle: css({
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, .5)',

    '& a': {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, .7)',
    },
  }),
})

export default HeadBar
