import _ from 'lodash'
import pluralize from 'pluralize'
import { Model } from 'redux-orm'
import OrmUtils from '../../helpers/OrmUtils'
import {
  doubleModelAssociationRefresh,
  modelAssociationRefresh,
  modelClassToModelName,
  modelCreate,
  modelDelete,
  modelPhotoUpload,
  modelRefresh,
  modelSaving,
  modelSavingChanges,
} from '../ormHelper'
import { session } from '../schemas'

export class QurttyModel extends Model {
  static withId: any
  id: any
  modelUrl: any
  model_class: any
  $_changes: any

  constructor (...args) {
    super(...args)
    this.modelUrl = pluralize(_.snakeCase((this.constructor as any).modelName.replace('V3_', '').replace('_', '00')).replace('_00_', '/'))
    this.$_changes = {}
  }

  /**
   * GET this model instance by id
   * @param  {Object} [params={}] qurty params
   * @return {Promise}             a promise of the updated model instance
   */
  $refresh (params = {}) {
    return modelRefresh(this.modelUrl, this, params)
  }

  $refreshAssociation (associationName, params) {
    return modelAssociationRefresh(this.modelUrl, associationName, this, params)
  }

  $doubleRefreshAssociation (associationName, params) {
    return doubleModelAssociationRefresh(this.modelUrl, associationName, this, params)
  }

  /**
   * PUT properties you want
   * @param  {Object or Array} args {name: 'Sherman', gender: 'unknown'} or ['name', 'gender']
   * @return {Promise}      a promise of saved model instance
   */
  $save (...args) {
    return modelSaving(this.modelUrl, this.id, this)(...args)
  }

  /**
   * PUT changed properties only (from $_changes)
   * @param  {Array} args e.g. ['name', 'gender'], will PUT only changed properties
   * @return {Promise}      a promise of saved model instance
   */
  $saveChanges (...args) {
    // console.log(args)
    return modelSavingChanges(this.modelUrl, this.id, this)(...args)
  }

  /**
   * DELETE the model instance
   * @return {Promise} of the deleted model instance
   */
  $destroy () {
    return modelDelete(this.modelUrl, this.id, this)()
  }

  /**
   * POST a new model instance
   * @param  {String} associationName the nested resource path given by API doc
   * @param  {Object} params          request body
   * @return {Promise}                 of the created model instance
   */
  $createAssociation (associationName, params) {
    return modelCreate(associationName, this, params)
  }

  $createAss (associationName, params) {
    return this.$createAssociation(associationName, params)
  }

  /**
   * POST new Photo model instance(s)
   * @param  {String} associationName the nested resource path given by API doc
   * @param  {Array} fileList        array of photo files
   * @param  {Object} params          additional request body
   * @return {Promise}                 of the created Photo model instances
   */
  $createPhotoAssociation (associationName, fileList, params) {
    const url = `${this.modelUrl}/${this.id}/${associationName}`
    return modelPhotoUpload(url, fileList, params)
  }

  getAssociation (attributeName, filterArg = null) {
    if (!this[attributeName]) {
      console.log('Can not find association: ', attributeName, 'for ', this)
    }
    if (filterArg) {
      return this[attributeName].filter(filterArg).orderBy('display_order', 'asc').toModelArray()
    } else {
      return this[attributeName].orderBy('display_order', 'asc').toModelArray()
    }
  }

  getAss (attributeName, filterArg = null) {
    return this.getAssociation(attributeName, filterArg)
  }

  getPolymorphicMany (associationModelClass, selfAs) {
    return session[modelClassToModelName(associationModelClass)].filter(
      (rec) => rec[`${selfAs}_type`] === this.model_class && rec[`${selfAs}_id`] === this.id,
    )
  }

  getPolymorphicOne (associationModelClass, selfAs) {
    return this.getPolymorphicMany(associationModelClass, selfAs).at(0)
  }

  getPolymorphicHolder (holderAs) {
    return session[modelClassToModelName(this[`${holderAs}_type`])].withId(this[`${holderAs}_id`])
  }

  $refreshAss (associationName, params) {
    return this.$refreshAssociation(associationName, params)
  }

  $doubleRefreshAss (associationName, params) {
    return this.$doubleRefreshAssociation(associationName, params)
  }

  originModelName () {
    let proto = this
    let name = proto.constructor.name
    while (name !== 'QurttyModel' || name.includes('Δ')) {
      // to delete all inherited parents in orm
      proto = Object.getPrototypeOf(proto)
      name = proto.constructor.name
    }
    return name
  }

  getClassSlashId () {
    return OrmUtils.getClassSlashId(this)
  }
}
