import { QurttyModel } from './modelDefault'

export class V5_Photo extends QurttyModel {
  static modelName = 'V5_Photo'
  static fields = {
  }

  icon_url: any

  getUrl (urlName) {
    return this[urlName] || this.icon_url
  }
}

export const registerV5PhotoModels = (orm) => {
  orm.register(
    V5_Photo,
  )
}
