import React, { forwardRef } from 'react'
import LANG from '../../../constants/LANG'

import Template2Paper from './Template2Paper'

interface FactsheetTemplate2Props {
  factsheets: any,
  lang: string
}

function FactsheetTemplate2 ({ factsheets, lang }: FactsheetTemplate2Props, ref) {
  const locale = LANG[lang]

  return (
    <div ref={ref}>
      {
        factsheets.map((factsheet, fcstIdx) => (
          <Template2Paper key={fcstIdx} factsheet={factsheet} locale={locale} />
        ))
      }
    </div>
  )
}

export default forwardRef(FactsheetTemplate2)
