import {
  fk,
} from 'redux-orm'
import { polymorphicAssoiciations } from '../../helpers/polymorphicHelper'
import { session } from '../schemas'
import { QurttyModel } from './modelDefault'

export class V3_Event extends QurttyModel {
  static modelName = 'V3_Event'
  static fields = {
  }

  static INCLUDES_PARAMS = {
    photos: {},
    linked_values: {},
    requestabilities: {},
    particle_requests: {},
    purchasabilities: {},
    subevent_groups: {},
    event_groupings: {},
    event_tickets: {},
    event_purchases: {},
    extension_entity: { values: {} },
    category_exposures: {},
  }

  photo_ids: number[]

  getPhotos () {
    return this.photo_ids.map((id) => session.V3_Photo.withId(id))
  }

  getExternalPages () {
    return this.getPolymorphicMany('V3_ExternalPage', 'holder').toModelArray()
  }

  getExtensionEntity () {
    return this.getPolymorphicOne('V3_Eav_Entity', 'extended_particle')
  }

  getProductSegments () {
    // return filter(session.V3_ProductSegment, val => val.bloggable_type === 'V3::Blog' && val.bloggable_id === this.id).toModelArray()
    return polymorphicAssoiciations(this, 'V3_ProductSegment', 'V3::Event', 'bloggable')
  }

  getPhotoablePhotoParticipations () {
    return [
      ...this.getAssociation('event_in_project_photo_participations'),
      ...this.getAssociation('event_in_doc_photo_participations'),
    ]
  }
}

export class V3_SubeventGroup extends QurttyModel {
  static modelName = 'V3_SubeventGroup'

  static fields = {
    event_id: fk({ to: 'V3_Event', as: 'event', relatedName: 'subevent_groups' }),
  }
}

export class V3_EventGrouping extends QurttyModel {
  static modelName = 'V3_EventGrouping'

  static fields = {
    subevent_group_id: fk({ to: 'V3_SubeventGroup', as: 'subevent_group', relatedName: 'event_groupings' }),
    event_id: fk({ to: 'V3_Event', as: 'event', relatedName: 'event_groupings' }),
  }
}

export class V3_EventPaper extends QurttyModel {
  static modelName = 'V3_EventPaper'
  static fields = {
    event_id: fk({ to: 'V3_Event', as: 'event', relatedName: 'event_papers' }),
  }

  getProductSegments () {
    // return filter(session.V3_ProductSegment, val => val.bloggable_type === 'V3::Blog' && val.bloggable_id === this.id).toModelArray()
    return polymorphicAssoiciations(this, 'V3_ProductSegment', 'V3::EventPaper', 'bloggable')
  }

  name (locale = 'en') {
    return this['name_' + locale]
  }
}

export const eventModelRegister = (orm) => {
  orm.register(
    V3_Event,
    V3_SubeventGroup,
    V3_EventGrouping,
    V3_EventPaper,
  )
}
