// resources url -> modelName
export const doubleAPILookupList = {
  received_friend_cards: { modelName: 'V3_MemberCard' },
  card_addresses: { modelName: 'V3_Address' },
  card_emails: { modelName: 'V3_Email' },
  card_phones: { modelName: 'V3_Phone' },
  participant_docs: { modelName: 'V3_Doc' },
  'paper_trail/versions': { modelName: 'V3_Version' },
  academic_records: { modelName: 'V3_Recruit_AcademicRecord' },
  awards: { modelName: 'V3_Recruit_Award' },
  questions: { modelName: 'V3_Recruit_Question' },
  work_experiences: { modelName: 'V3_Recruit_WorkExperience' },
  assigned_roles: { modelName: 'V3_Johnpcarty_AssignedRole' },
  'v5/shared/staff_roles': { modelName: 'V5_Shared_StaffRole' },
  received_project_single_invitations: { modelName: 'V3_ProjectSingleInvitation' },
}

// special association name -> resources url
export const associationNameLookupList = {
  requested_friendships: 'friendships',
  bag_photo_participations: 'photo_participations',
  structures: 'eav/structures',
  entities: 'eav/entities',
  attrs: 'eav/attrs',
  values: 'eav/values',
  entity_permissions: 'eav/entity_permissions',
  entity_comments: 'eav/entity_comments',
  child_departments: 'departments',
  versions: 'paper_trail/versions',
}

export const modelClassToModelNameLookupList = {
  'V3::Card::Phone': 'V3_Phone',
  'V3::Card::Address': 'V3_Address',
  'V3::Card::Email': 'V3_Email',
  'V3::Card::Website': 'V3_Website',
  'V3::Card::Membership': 'V3_Membership',
}

export const modelNamePolymorphicLookupList = {
  V3_Website: ['ΔV3_MemberCardΔWebsite', 'ΔV3_CompanyCardΔWebsite', 'ΔV3_BusinessCardΔWebsite'],
  V3_Version: ['ΔV3_PhotoΔVersion', 'ΔV3_MemberCardΔVersion', 'ΔV3_CompanyCardΔVersion', 'ΔV3_BusinessCardΔVersion', 'ΔV3_ProductΔVersion', 'ΔV3_WebsiteΔVersion', 'ΔV3_PhoneΔVersion', 'ΔV3_AddressΔVersion', 'ΔV3_EmailΔVersion', 'ΔV3_Eav_ValueΔVersion'],
}
