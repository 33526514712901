import { QurttyModel } from './modelDefault'

export class V3_Johnpcarty_AssignedRole extends QurttyModel {
  static modelName = 'V3_Johnpcarty_AssignedRole'
  modelUrl = 'johnpcarty/assigned_roles'

  static fields = {}
}

export const johnpcartyModelRegister = (orm) => {
  orm.register(V3_Johnpcarty_AssignedRole)
}
