import { get } from 'lodash'
import {
  fk, many, oneToOne,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_Company extends QurttyModel {
  static modelName = 'V3_Company'
  static fields = {}

  getPhotoUrl (urlName) {
    return get(this, 'v5_company.most_used_logo.' + urlName)
  }
}

export class V5_Company extends QurttyModel {
  static modelName = 'V5_Company'
  static fields = {
    most_used_logo_id: fk({ to: 'V5_Photo', as: 'most_used_logo', relatedName: '_companies' }),
    _paid_v3_website_projects_ids: many({ to: 'V3_Project', as: '_paid_v3_website_projects', relatedName: '_v5_companies' }),
    m2m_company_card_alias_industry_exposures_ids: many({ to: 'V5_IndustryExposure', as: 'm2m_company_card_alias_industry_exposures', relatedName: '_companies' }),
    hand4_v3_primary_website_project_id: oneToOne({ to: 'V3_Project', as: 'hand4_v3_primary_website_project', relatedName: '_v5_company' }),
  }

  getPhotoUrl (urlName) {
    return get(this, 'most_used_logo.' + urlName)
  }
}

export class V5_Department extends QurttyModel {
  static modelName = 'V5_Department'
  static fields = {
    parent_department_id: fk({ to: 'V5_Department', as: 'parent_department', relatedName: 'child_departments' }),
    _hierarchy_departments_ids: many({ to: 'V5_Department', as: '_hierarchy_departments', relatedName: '_ancestor_department' }),
  }

  parent_department: any

  branch () {
    return [...(this.parent_department ? this.parent_department.branch() : []), this]
  }

  branchNames (lo = 'en') {
    return this.branch().map((indst) => indst[`name_${lo}`] || '')
  }
}

export class V5_StaffRole extends QurttyModel {
  static modelName = 'V5_StaffRole'
  static fields = {}
}

export class V5_Shared_StaffRole extends QurttyModel {
  static modelName = 'V5_Shared_StaffRole'
  static fields = {}
}

export class V3_AssetPool extends QurttyModel {
  static modelName = 'V3_AssetPool'
  static fields = {
    company_id: fk({ to: 'V3_Company', as: 'company', relatedName: 'asset_pool' }),
  }
}

export class V3_Department extends QurttyModel {
  static modelName = 'V3_Department'

  static fields = {
    company_id: fk({ to: 'V3_Company', as: 'company', relatedName: 'departments' }),
    parent_department_id: fk({ to: 'V3_Department', as: 'parent_department', relatedName: 'child_departments' }),
  }
}

export const companyModelRegister = (orm) => {
  orm.register(V3_Company, V5_Company, V5_Department, V5_StaffRole, V5_Shared_StaffRole, V3_AssetPool, V3_Department)
}
