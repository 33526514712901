const traverse = (obj, func, parentObjKeyPairs = []) => {
  // example: { main: exampleSection }
  // const exampleSection = {
  //   id: 1,
  //   model_class: 'V5::ContactCompanySection',
  //   company: {
  //     id: 3,
  //     model_class: 'V5::Company',
  //     m2m_company_card_alias_industry_exposures: [
  //       { id: 71, model_class: 'V5::IndustryExposure' },
  //       { id: 72, model_class: 'V5::IndustryExposure' },
  //     ],
  //   },
  // }

  Object.keys(obj).forEach((key) => {
    const val = obj[key]

    if (typeof val === 'object' && val !== null) {
      // step 1: { main: exampleSection }
      // step 4: { company: { xxx } }
      // step 7: { m2m_company_card_alias_industry_exposures: [ xxx ] }
      // step 8: [ 0: { xxx } ] (inside exposuresArray)
      // step 12: [ 1: { xxx } ] (inside exposuresArray)
      const theParentObjKeyPairs = [...parentObjKeyPairs]
      theParentObjKeyPairs.push([obj, key])
      traverse(val, func, theParentObjKeyPairs)
    }

    // step 2: { id: 1 } (inside exampleSection)
    // step 3: { model_class: 'V5::ContactCompanySection' }
    // step 5: { id: 3 } (inside companyObj)
    // step 6: { model_class: 'V5::Company' }
    // step 9: { id: 71 } (inside exposure)
    // step 10: { model_class: 'V5::IndustryExposure' }
    // step 11: [ 0: { xxx } ] (leaving exposure)
    // step 13: { id: 72 } (inside exposure)
    // step 14: { model_class: 'V5::IndustryExposure' }
    // step 15: [ 1: { xxx } ] (leaving exposure)
    // step 16: { m2m_company_card_alias_industry_exposures: [ xxx ] } (leaving exposuresArray)
    // step 17: { company: { xxx } } (leaving companyObj)
    // step 18: { main: exampleSection } (leaving exampleSection)
    func(obj, key, parentObjKeyPairs)
  })
}

export default {
  traverse,
}
