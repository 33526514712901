import { forwardRef } from 'react'
import Template1Paper from './Template1Paper'

function FactsheetTemplate1 ({ factsheets }, ref) {
  return (
    <div ref={ref}>
      {
        factsheets.map((factsheet, fcstIdx) => (
          <Template1Paper key={fcstIdx} factsheet={factsheet} />
        ))
      }
    </div>
  )
}

export default forwardRef(FactsheetTemplate1)
