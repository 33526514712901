import { QurttyModel } from './modelDefault'

export class V3_Fund_Factsheet extends QurttyModel {
  static modelName = 'V3_Fund_Factsheet'
  static fields = {
  }

  static INCLUDES_PARAMS = {
    fund: { asset_values: {} },
    footnotes: {},
    performances: { performance_items: {} },
    holdings: {},
    theme_allocations: {},
    sector_allocations: {},
    asset_allocations: {},
    country_allocations: {},
    credit_allocations: {},
    maturity_allocations: {},
    fund_asset_values: {},
    risk_ratings: {},
    fund_sections: {},
    hsb_exhibits: {},
    hsbc_exhibits: {},
    uob_exhibits: {},
  }
}

export const registerFundModel = (orm) => {
  orm.register(V3_Fund_Factsheet)
}
