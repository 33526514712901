import { css } from '@emotion/css/macro'
import { useTheme } from '@emotion/react'
import React, { useState } from 'react'
import FactsheetPreview from './FactsheetPreview'
import HeadBar from './HeadBar'
import InstructionPanel from './InstructionPanel'
import TemplateControlBar from './TemplateControlBar'
import factsheets from './data/factsheets'
import Loading from './shared/Loading'

function App () {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [loading, setLoading] = useState(false)
  const [previewingFactsheets, setPreviewingFactsheets] = useState(factsheets)

  const [lang, setLang] = useState('en')
  const [template, setTemplate] = useState(2)

  const [instructionPanelOpen, setInstructionPanelOpen] = useState(false)

  return (
    <div className={classes.container}>
      <div className={css({ width: '100%', '@media print': { display: 'none' } })}>
        <HeadBar />
      </div>
      <div className={classes.main}>
        <div className={classes.leftPanel(instructionPanelOpen)}>
          <InstructionPanel setPreviewingFactsheets={setPreviewingFactsheets} setLoading={setLoading} setInstructionPanelOpen={setInstructionPanelOpen} />
        </div>
        <div className={classes.rightPanel(instructionPanelOpen)}>
          <div>
            <TemplateControlBar lang={lang} setLang={setLang} template={template} setTemplate={setTemplate} setInstructionPanelOpen={setInstructionPanelOpen} />
          </div>
          <div>
            <FactsheetPreview factsheets={previewingFactsheets} template={template} lang={lang} />
          </div>
        </div>
      </div>
      {
        loading && (
          <Loading />
        )
      }
    </div>
  )
}

const useStyles = (theme) => ({
  container: css({
    backgroundColor: 'rgba(0,100,250,0.1)',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '@media print': {
      height: 'auto',
    },
  }),
  main: css({
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden',
  }),
  leftPanel: (leftPanelOpen: boolean) => css({
    flex: '1 1 50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'auto',
    transition: 'flex .3s ease-in',

    '@media print': { display: 'none' },
    '@media screen and (max-width: 834px) ': {
      flex: leftPanelOpen ? '1 1 100vw' : '0 1 0',
    },
  }),
  rightPanel: (leftPanelOpen: boolean) => css({
    flex: '1 0 calc(420mm * 0.6)',
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: 'flex .3s ease-out',

    '@media screen and (max-width: 834px) ': {
      flex: leftPanelOpen ? '0 1 0' : '1 0 100vw',
    },
    '@media print': {
      zoom: 0.5,
    },
  }),
})

export default App
