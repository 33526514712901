import {
  fk,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_RegistrationInvitation extends QurttyModel {
  static modelName = 'V3_RegistrationInvitation'
  static fields = {
    inviter_id: fk({ to: 'Member', as: 'inviter' }),
    invitee_id: fk({ to: 'Member', as: 'invitee', relatedName: 'received_registration_invitations' }),
    invitee_card_email_id: fk({ to: 'V3_Email', as: 'invitee_card_email', relatedName: 'received_registration_invitations' }),
  }
}

export const invitationModelRegister = (orm) => {
  orm.register(V3_RegistrationInvitation)
}
