import { fk, oneToOne } from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V5_Phone extends QurttyModel {
  modelUrl = 'v5/phones'
  static modelName = 'V5_Phone'
  static fields = {
  }
}

export class V5_Email extends QurttyModel {
  modelUrl = 'v5/emails'
  static modelName = 'V5_Email'
  static fields = {
  }
}

export class V5_Address extends QurttyModel {
  modelUrl = 'v5/addresses'
  static modelName = 'V5_Address'
  static fields = {
  }
}

export class V5_PhoneExposure extends QurttyModel {
  static modelName = 'V5_PhoneExposure'
  static fields = {
    phone_id: fk({ to: 'V5_Phone', as: 'phone', relatedName: 'phone_exposures' }),
  }
}
export class V5_EmailExposure extends QurttyModel {
  static modelName = 'V5_EmailExposure'
  static fields = {
    email_id: fk({ to: 'V5_Email', as: 'email', relatedName: 'email_exposures' }),
  }
}
export class V5_AddressExposure extends QurttyModel {
  static modelName = 'V5_AddressExposure'
  static fields = {
    address_id: fk({ to: 'V5_Address', as: 'address', relatedName: 'address_exposures' }),
  }
}

export class V5_WorkExperience extends QurttyModel {
  static modelName = 'V5_WorkExperience'
  static fields = {
    staff_id: oneToOne({ to: 'V5_Staff', as: 'staff', relatedName: 'work_experience' }),
  }
}
export class V5_WorkExperienceExposure extends QurttyModel {
  static modelName = 'V5_WorkExperienceExposure'
  static fields = {
    work_experience_id: fk({ to: 'V5_WorkExperience', as: 'work_experience', relatedName: 'work_experience_exposures' }),
  }
}
export class V5_WorkDuty extends QurttyModel {
  static modelName = 'V5_WorkDuty'
  static fields = {
  }
}
export class V5_WorkDutyExposure extends QurttyModel {
  static modelName = 'V5_WorkDutyExposure'
  static fields = {
    work_experience_exposure_id: fk({ to: 'V5_WorkExperienceExposure', as: 'work_experience_exposure', relatedName: 'work_duty_exposures' }),
    work_duty_id: fk({ to: 'V5_WorkDuty', as: 'work_duty', relatedName: 'work_duty_exposures' }),
  }
}
export class V5_WorkAchievement extends QurttyModel {
  static modelName = 'V5_WorkAchievement'
  static fields = {
  }
}
export class V5_WorkAchievementExposure extends QurttyModel {
  static modelName = 'V5_WorkAchievementExposure'
  static fields = {
    work_experience_exposure_id: fk({ to: 'V5_WorkExperienceExposure', as: 'work_experience_exposure', relatedName: 'work_achievement_exposures' }),
    work_achievement_id: fk({ to: 'V5_WorkAchievement', as: 'work_achievement', relatedName: 'work_achievement_exposures' }),
  }
}

export class V5_IndustryExposure extends QurttyModel {
  static modelName = 'V5_IndustryExposure'
  static fields = {
    shared_industry_id: fk({ to: 'V5_Shared_Industry', as: 'shared_industry', relatedName: 'industry_exposures' }),
  }
}

export class V5_Shared_Industry extends QurttyModel {
  modelUrl = 'v5/shared/industries'

  static modelName = 'V5_Shared_Industry'
  static fields = {
    parent_industry_id: fk({ to: 'V5_Shared_Industry', as: 'parent_industry', relatedName: 'child_industries' }),
  }

  parent_industry: any

  branch () {
    return [...(this.parent_industry ? this.parent_industry.branch() : []), this]
  }

  branchNames (lo = 'en') {
    return this.branch().map((indst) => indst[`name_${lo}`] || '')
  }
}

export const registerV5ExposuresModels = (orm) => {
  orm.register(
    V5_Phone, V5_Email, V5_Address,
    V5_PhoneExposure, V5_EmailExposure, V5_AddressExposure,
    V5_WorkExperience, V5_WorkDuty, V5_WorkAchievement,
    V5_WorkExperienceExposure, V5_WorkDutyExposure, V5_WorkAchievementExposure,
    V5_Shared_Industry,
    V5_IndustryExposure,
  )
}
