import * as qurttyApiHelper from '../api/apiHelper'
import { V3_Eav_Structure } from '../orm/models/projectModel'

export const getProjects = (proj_id, params = {}) => {
  if (proj_id) {
    return qurttyApiHelper.getSingleResource('projects', proj_id, params).then((prj) => {
      console.log('getSingleResource', prj)
      return qurttyApiHelper.getResources('docs', {
        project_id: proj_id,
        includes: JSON.stringify({
          structure: V3_Eav_Structure.INCLUDES_PARAMS,
          docs: {
            structure: V3_Eav_Structure.INCLUDES_PARAMS,
            docs: {
              structure: V3_Eav_Structure.INCLUDES_PARAMS,
              docs: {
                structure: V3_Eav_Structure.INCLUDES_PARAMS,
                docs: {
                  structure: V3_Eav_Structure.INCLUDES_PARAMS,
                },
              },
            },
          },
        }),
      }).then(() => {
        return [prj]
      })
    })
  } else {
    return qurttyApiHelper.getResources('projects', {}).then((resp) => {
      console.log('getResources', resp)
      return resp
    })
  }
}

export const getStructures = (project, stru_id) => { // Single Project All
  if (!project) return null
  const structures = project.getStructures()
  if (stru_id) {
    return structures.filter((stru) => stru.id === parseInt(stru_id))
  } else {
    return structures
  }
}
