/*
 * First level of API call.
 */
import { AxiosRequestConfig } from 'axios'
import axios from '../api/axiosSetting'
// MemberCard API Card
// Getter

/**
 * GET request for resources
 * This function is used by APIHelper to get model instance list and store it into ORM.
 * @param  {String} resourcesUrl  resources url
 * @param  {Object} [apiParam={}] API Query String parameter
 * @param  {Function} afterAPI      callback
 * @return {Promise}               Promise of resources
 */
export const getResourcesAPI = (resourcesUrl, apiParam: any = {}, config: AxiosRequestConfig = {}) => {
  let promise
  if (apiParam.specific === true) {
    promise = axios.post(resourcesUrl, apiParam, config)
  } else {
    config.params = config.params || {}
    Object.assign(config.params, apiParam)
    promise = axios.get(resourcesUrl, config)
  }
  return promise
}

/**
 * http GET one resource
 * @param  {String} resourcesUrl  url
 * @param  {Object} [apiParam={}] query params
 * @param  {Function} afterAPI      callback
 * @return {Promise}               of the single resource
 */
export const getSingleResourceAPI = (resourcesUrl, id, apiParam = {}, config: AxiosRequestConfig = {}) => {
  config.params = config.params || {}
  Object.assign(config.params, apiParam)
  return axios.get(`${resourcesUrl}/${id}`, config)
}

export const getUserAccountAPI = (memberID, apiParam = {}, afterAPI = null) => {
  return axios.get(`members/${memberID}`, { params: apiParam }).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const getInviteeAPI = (invitationToken, apiParam = {}, afterAPI = null) => {
  return axios.get(`invitees/${invitationToken}`, { params: apiParam }).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const signUpAsInvited = (apiParam = {}, afterAPI = null) => {
  return axios.put('members/invitation', apiParam).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const forgetPassword = (apiParam = {}, afterAPI = null) => {
  return axios.post('auth/password', apiParam).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const getSingleMemberCardAPI = (memberID, apiParam = {}, afterAPI = null) => {
  return axios.get(`member_cards/${memberID}`, { params: apiParam }).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

// Creater
export const createMemberCardAPI = (param, afterAPI = null) => {
  return axios.post('member_cards', param).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const createHoldMemberCardAPI = (memberID, param, afterAPI = null) => {
  return axios.post(`/members/${memberID}/hold_member_cards`, param).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const uploadPhoto = (url, formData) => {
  return axios.post(url, formData)
}

export const getPhotoListAPI = (apiParam = {}, afterAPI = null) => {
  return axios.get('photos', { params: apiParam }).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const getAlbumListAPI = (apiParam = {}, afterAPI = null) => {
  return axios.get('albums', { params: apiParam }).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

export const getSocietyListAPI = (apiParam = {}, afterAPI = null) => {
  return axios.get('societies', { params: apiParam }).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}

// Return list of association
export const modelAssociationRefreshAPI = (url, params: any = {}, afterAPI = null) => {
  let promise
  if (params.specific === true) {
    promise = axios.post(url, params)
  } else {
    promise = axios.get(url, { params })
  }
  return promise.then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}
//
export const modelCreateAPI = (url, param, afterAPI = null) => {
  return axios.post(url, param).then((resp) => {
    return resp.data
  }).then((resp) => {
    if (afterAPI) {
      return afterAPI(resp)
    } else {
      return resp
    }
  })
}
// Get
export const modelRefreshAPI = (url, id, param) => {
  return axios.get(`${url}/${id}`, { params: param }).then((resp) => {
    return resp.data
  })
}
// Editor
export const modelSavingAPI = (url, id, param) => {
  const axiosConfig = param.constructor.name === 'FormData' ? { headers: { 'Content-Type': 'multipart/form-data' } } : {}
  return axios.put(`${url}/${id}`, param, axiosConfig).then((resp) => {
    return resp.data
  })
}
// Delete
export const modelDeleteAPI = (url, id) => {
  return axios.delete(`${url}/${id}`)
}

export const checkEmailType = (email) => {
  return axios.get('members/check', { params: { email } })
}
