import axios from 'axios'
import authHelper from './authHelper'

axios.interceptors.request.use(async function (config) {
  const token = await authHelper.getAuthToken()
  const sessionUuid = await authHelper.getAuthSessionUuid()

  if (token) { config.headers.authorization = `Bearer ${token}` }
  if (sessionUuid) { config.headers['auth-session-uuid'] = sessionUuid }

  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  authHelper.setAuthToken(response.headers)
  return response
}, function (error) {
  return Promise.reject(error)
})

export default axios
