import {
  fk,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_HkPostal_Zone extends QurttyModel {
  static modelName = 'V3_HkPostal_Zone'
  static fields = {}
}

export class V3_HkPostal_District extends QurttyModel {
  static modelName = 'V3_HkPostal_District'
  static fields = {
    zone_id: fk({ to: 'V3_HkPostal_Zone', as: 'zone', relatedName: 'districts' }),
  }
}

export class V3_HkPostal_Street extends QurttyModel {
  static modelName = 'V3_HkPostal_Street'
  static fields = {
    district_id: fk({ to: 'V3_HkPostal_District', as: 'district', relatedName: 'streets' }),
  }
}

export class V3_HkPostal_StreetNumber extends QurttyModel {
  static modelName = 'V3_HkPostal_StreetNumber'
  static fields = {
    district_id: fk({ to: 'V3_HkPostal_District', as: 'district', relatedName: 'street_numbers' }),
    street_id: fk({ to: 'V3_HkPostal_Street', as: 'street', relatedName: 'street_numbers' }),
  }
}

export class V3_HkPostal_Estate extends QurttyModel {
  static modelName = 'V3_HkPostal_Estate'
  static fields = {
    district_id: fk({ to: 'V3_HkPostal_District', as: 'district', relatedName: 'estates' }),
  }
}

export class V3_HkPostal_Phase extends QurttyModel {
  static modelName = 'V3_HkPostal_Phase'
  static fields = {
    estate_id: fk({ to: 'V3_HkPostal_Estate', as: 'estate', relatedName: 'phases' }),
  }
}

export class V3_HkPostal_Building extends QurttyModel {
  static modelName = 'V3_HkPostal_Building'
  static fields = {
    district_id: fk({ to: 'V3_HkPostal_District', as: 'district', relatedName: 'buildings' }),
    estate_id: fk({ to: 'V3_HkPostal_Estate', as: 'estate', relatedName: 'buildings' }),
    phase_id: fk({ to: 'V3_HkPostal_Phase', as: 'phase', relatedName: 'buildings' }),
  }
}

export class V3_HkPostal_Floor extends QurttyModel {
  static modelName = 'V3_HkPostal_Floor'
  static fields = {
    building_id: fk({ to: 'V3_HkPostal_Building', as: 'building', relatedName: 'floors' }),
  }
}

export class V3_HkPostal_Unit extends QurttyModel {
  static modelName = 'V3_HkPostal_Unit'
  static fields = {
    floor_id: fk({ to: 'V3_HkPostal_Floor', as: 'floor', relatedName: 'units' }),
  }
}

export class V3_HkPostal_StreetBuilding extends QurttyModel {
  static modelName = 'V3_HkPostal_StreetBuilding'
  static fields = {
    street_id: fk({ to: 'V3_HkPostal_Street', as: 'street', relatedName: 'street_buildings' }),
    building_id: fk({ to: 'V3_HkPostal_Building', as: 'building', relatedName: 'street_buildings' }),
  }
}

export class V3_HkPostal_StreetEstate extends QurttyModel {
  static modelName = 'V3_HkPostal_StreetEstate'
  static fields = {
    street_id: fk({ to: 'V3_HkPostal_Street', as: 'street', relatedName: 'street_estates' }),
    estate_id: fk({ to: 'V3_HkPostal_Estate', as: 'estate', relatedName: 'street_estates' }),
  }
}

export class V3_HkPostal_StreetNumberBuilding extends QurttyModel {
  static modelName = 'V3_HkPostal_StreetNumberBuilding'
  static fields = {
    street_number_id: fk({ to: 'V3_HkPostal_StreetNumber', as: 'street_number', relatedName: 'street_number_buildings' }),
    building_id: fk({ to: 'V3_HkPostal_Building', as: 'building', relatedName: 'street_number_buildings' }),
  }
}

export class V3_HkPostal_StreetNumberEstate extends QurttyModel {
  static modelName = 'V3_HkPostal_StreetNumberEstate'
  static fields = {
    street_number_id: fk({ to: 'V3_HkPostal_StreetNumber', as: 'street_number', relatedName: 'street_number_estates' }),
    estate_id: fk({ to: 'V3_HkPostal_Estate', as: 'estate', relatedName: 'street_number_estates' }),
  }
}

export const hkPostalModelRegister = (orm) => orm.register(
  V3_HkPostal_Zone,
  V3_HkPostal_District,
  V3_HkPostal_Street,
  V3_HkPostal_StreetNumber,
  V3_HkPostal_Estate,
  V3_HkPostal_Phase,
  V3_HkPostal_Building,
  V3_HkPostal_Floor,
  V3_HkPostal_Unit,
  V3_HkPostal_StreetBuilding,
  V3_HkPostal_StreetEstate,
  V3_HkPostal_StreetNumberBuilding,
  V3_HkPostal_StreetNumberEstate,
)
