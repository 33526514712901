import {
  fk, oneToOne,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

const getPhones = function () {
  return this.getPolymorphicMany('V3_Phone', 'card').toModelArray()
}
const getEmails = function () {
  return this.getPolymorphicMany('V3_Email', 'card').toModelArray()
}
const getAddresses = function () {
  return this.getPolymorphicMany('V3_Address', 'card').toModelArray()
}

const getPhoneExposures = function () {
  return this.getPolymorphicMany('V5_PhoneExposure', 'card').toModelArray()
}
const getEmailExposures = function () {
  return this.getPolymorphicMany('V5_EmailExposure', 'card').toModelArray()
}
const getAddressExposures = function () {
  return this.getPolymorphicMany('V5_AddressExposure', 'card').toModelArray()
}
const getWorkDuties = function () {
  return this.getPolymorphicMany('V5_WorkDuty', 'work_experience').toModelArray()
}
const getWorkAchievements = function () {
  return this.getPolymorphicMany('V5_WorkAchievement', 'work_experience').toModelArray()
}

export class V3_Employment extends QurttyModel {
  static modelName = 'V3_Employment'

  static fields = {
    company_id: fk({ to: 'V3_Company', as: 'company', relatedName: 'employments' }),
    employee_master_id: fk({ to: 'Member', as: 'employee_master', relatedName: 'employments' }),
  }
}

export class V3_EmploymentDepartment extends QurttyModel {
  static modelName = 'V3_EmploymentDepartment'

  static fields = {
    employment_id: fk({ to: 'V3_Employment', as: 'employment', relatedName: 'employment_departments' }),
    department_id: fk({ to: 'V3_Department', as: 'department', relatedName: 'employment_departments' }),
  }
}

export class V5_Employment extends QurttyModel {
  static modelName = 'V5_Employment'

  static fields = {
    company_id: fk({ to: 'V5_Company', as: 'company', relatedName: 'employments' }),
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'v5_employments' }),
  }
}

export class V5_Staff extends QurttyModel {
  static modelName = 'V5_Staff'

  static fields = {
    employment_id: fk({ to: 'V5_Employment', as: 'employment', relatedName: 'v5_my_staff_accounts' }),
    department_id: fk({ to: 'V5_Department', as: 'department', relatedName: 'staffs' }),
    staff_role_id: fk({ to: 'V5_StaffRole', as: 'staff_role', relatedName: 'staffs' }),
  }

  getRolePhones = getPhones
  getRoleEmails = getEmails
  getRoleAddresses = getAddresses
}
export class V5_StaffCard extends QurttyModel {
  static modelName = 'V5_StaffCard'

  static fields = {
    staff_id: oneToOne({ to: 'V5_Staff', as: 'staff', relatedName: 'staff_card' }),
    m2m_company_card_alias_id: fk({ to: 'V5_CompanyCardAlias', as: 'm2m_company_card_alias', relatedName: '_staff_cards' }),
  }

  getRolePhoneExposures = getPhoneExposures
  getRoleEmailExposures = getEmailExposures
  getRoleAddressExposures = getAddressExposures
}
export class V5_StaffSnapshot extends QurttyModel {
  static modelName = 'V5_StaffSnapshot'

  static fields = {
    staff_id: oneToOne({ to: 'V5_Staff', as: 'staff', relatedName: 'staff_snapshot' }),
    department_id: fk({ to: 'V5_Department', as: 'department', relatedName: 'staff_snapshots' }),
    staff_role_id: fk({ to: 'V5_StaffRole', as: 'staff_role', relatedName: 'staff_snapshots' }),
  }

  getWorkExperienceSnapshot () { return this.getPolymorphicOne('V5_WorkExperienceSnapshot', 'snapshot') }
}
export class V5_WorkExperienceSnapshot extends QurttyModel {
  static modelName = 'V5_WorkExperienceSnapshot'

  static fields = {
  }

  getWorkDuties = getWorkDuties
  getWorkAchievements = getWorkAchievements
}

export const employmentModelRegister = (orm) => {
  orm.register(V3_Employment, V3_EmploymentDepartment, V5_Employment, V5_Staff, V5_StaffCard, V5_StaffSnapshot, V5_WorkExperienceSnapshot)
}
