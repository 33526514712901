import { fk } from 'redux-orm'
import { QurttyModel } from './modelDefault'

const getPhoneExposures = function () {
  return this.getPolymorphicMany('V5_PhoneExposure', 'card').toModelArray()
}
const getEmailExposures = function () {
  return this.getPolymorphicMany('V5_EmailExposure', 'card').toModelArray()
}
const getAddressExposures = function () {
  return this.getPolymorphicMany('V5_AddressExposure', 'card').toModelArray()
}
const getWorkExperienceExposures = function () {
  return this.getPolymorphicMany('V5_WorkExperienceExposure', 'holder').toModelArray()
}

export class V5_MemberCard extends QurttyModel {
  static modelName = 'V5_MemberCard'
  static fields = {
    m2m_avatar_id: fk({ to: 'V5_Photo', as: 'm2m_avatar', relatedName: '_avatared_member_cards' }),
    m2m_background_id: fk({ to: 'V5_Photo', as: 'm2m_background', relatedName: '_backgrounded_member_cards' }),
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'v5_member_cards' }),
  }

  getPhoneExposures = getPhoneExposures
  getEmailExposures = getEmailExposures
  getAddressExposures = getAddressExposures
  getWorkExperienceExposures = getWorkExperienceExposures
}
export class V5_ContactCard extends QurttyModel {
  modelUrl = 'v5/contact_cards'

  static modelName = 'V5_ContactCard'
  static fields = {
    m2m_avatar_id: fk({ to: 'V5_Photo', as: 'm2m_avatar', relatedName: '_avatared_contact_cards' }),
    m2m_background_id: fk({ to: 'V5_Photo', as: 'm2m_background', relatedName: '_backgrounded_contact_cards' }),
  }

  static INCLUDES_PARAMS = {
    m2m_avatar: {},
    m2m_background: {},
    phones: {},
    emails: {},
    addresses: {},
    contact_company_sections: {
      company: { m2m_company_card_alias_industry_exposures: { shared_industry: { parent_industry: {} } } },
      phones: {},
      emails: {},
      addresses: {},
      contact_role_sections: { staff_role: {}, department: {}, phones: {}, emails: {}, addresses: {} },
    },
    card_accesses: {
      card: {
        phone_exposures: { phone: {} },
        email_exposures: { email: {} },
        address_exposures: { address: {} },
        staff: { work_experience: {}, staff_role: {}, department: {} },
        m2m_company_card_alias: { m2m_logo: {} },
        role_phone_exposures: { phone: {} },
        role_email_exposures: { email: {} },
        role_address_exposures: { address: {} },
        work_experience_exposures: {
          work_experience: {
            staff: {
              employment: { company: {} },
              department: {},
              staff_snapshot: { work_experience_snapshot: { work_duties: {}, work_achievements: {} } },
            },
          },
          work_duty_exposures: { work_duty: {} },
          work_achievement_exposures: { work_achievement: {} },
        },
        certificate_exposures: { certificate: {} },
      },
    },
    albums: {},
  }

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
}
export class V5_CompanyCardAlias extends QurttyModel {
  static modelName = 'V5_CompanyCardAlias'
  static fields = {
    m2m_logo_id: fk({ to: 'V5_Photo', as: 'm2m_logo', relatedName: '_logoed_company_card_aliases' }),
  }

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
}
export class V5_ContactCompanySection extends QurttyModel {
  static modelName = 'V5_ContactCompanySection'
  static fields = {
    contact_card_id: fk({ to: 'V5_ContactCard', as: 'contact_card', relatedName: 'contact_company_sections' }),
    company_id: fk({ to: 'V5_Company', as: 'company', relatedName: 'contact_company_sections' }),
    m2m_logo_id: fk({ to: 'V5_Photo', as: 'm2m_logo', relatedName: '_logoed_contact_company_sections' }),
  }

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
}
export class V5_ContactRoleSection extends QurttyModel {
  static modelName = 'V5_ContactRoleSection'
  static fields = {
    contact_company_section_id: fk({ to: 'V5_ContactCompanySection', as: 'contact_company_section', relatedName: 'contact_role_sections' }),
    staff_role_id: fk({ to: 'V5_StaffRole', as: 'staff_role', relatedName: 'contact_role_sections' }),
    department_id: fk({ to: 'V5_Department', as: 'department', relatedName: 'contact_role_sections' }),
  }

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
}
export class V5_CardAccess extends QurttyModel {
  static modelName = 'V5_CardAccess'
  static fields = {
    contact_card_id: fk({ to: 'V5_ContactCard', as: 'contact_card', relatedName: 'card_accesses' }),
  }

  getCard () {
    return this.getPolymorphicHolder('card')
  }
}

const getPhones = function () {
  return this.getPolymorphicMany('V5_Phone', 'holder').toModelArray()
}
const getEmails = function () {
  return this.getPolymorphicMany('V5_Email', 'holder').toModelArray()
}
const getAddresses = function () {
  return this.getPolymorphicMany('V5_Address', 'holder').toModelArray()
}

export const registerV5CardModels = (orm) => {
  orm.register(
    V5_MemberCard,
    V5_ContactCard,
    V5_CompanyCardAlias,
    V5_ContactCompanySection,
    V5_ContactRoleSection,
    V5_CardAccess,
  )
}
