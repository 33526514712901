import {
  fk,
} from 'redux-orm'
import { polymorphicAssoiciations } from '../../helpers/polymorphicHelper'
import { session } from '../schemas'
import { QurttyModel } from './modelDefault'

export class V3_BannerBlockPhoto extends QurttyModel {
  static modelName = 'V3_BannerBlockPhoto'

  static fields = {
    product_banner_block_id: fk({ to: 'V3_ProductBannerBlock', as: 'product_banner_block', relatedName: 'banner_block_photos' }),
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'banner_block_photos' }),
  }
}

export class V3_GenericBlockSlide extends QurttyModel {
  static modelName = 'V3_GenericBlockSlide'

  static fields = {
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'generic_block_slides' }),
  }

  static INCLUDES_PARAMS = {
    photo: {}, generic_block_action_buttons: {},
  }
}

export class V3_GenericBlockActionButton extends QurttyModel {
  static modelName = 'V3_GenericBlockActionButton'

  static fields = {
    generic_block_slide_id: fk({ to: 'V3_GenericBlockSlide', as: 'generic_block_slide', relatedName: 'generic_block_action_buttons' }),
  }
}

export class V3_BlockArrow extends QurttyModel {
  static modelName = 'V3_BlockArrow'
  modelUrl = 'v3/block_arrows'
  static fields = {
    start_block_id: fk({ to: 'V3_ProductGenericBlock', as: 'start_block', relatedName: 'start_block_arrows' }),
    end_block_id: fk({ to: 'V3_ProductGenericBlock', as: 'end_block', relatedName: 'end_block_arrows' }),
  }
}

export class V3_ProductHtmlBlock extends QurttyModel {
  static modelName = 'V3_ProductHtmlBlock'
  static fields = {
    product_segment_id: fk({ to: 'V3_ProductSegment', as: 'product_segment', relatedName: 'product_html_blocks' }),
    background_photo_id: fk({ to: 'V3_Photo', as: 'background_photo', relatedName: 'backgrounded_product_html_blocks' }),
  }
}

export class V3_ProductBannerBlock extends QurttyModel {
  static modelName = 'V3_ProductBannerBlock'

  static fields = {
    product_segment_id: fk({ to: 'V3_ProductSegment', as: 'product_segment', relatedName: 'product_banner_blocks' }),
    background_photo_id: fk({ to: 'V3_Photo', as: 'background_photo', relatedName: 'backgrounded_product_banner_blocks' }),
  }

  static INCLUDES_PARAMS = { photos: {}, banner_block_photos: { photo: {} } }
}

export class V3_ProductGenericBlock extends QurttyModel {
  static modelName = 'V3_ProductGenericBlock'

  static fields = {
    product_segment_id: fk({ to: 'V3_ProductSegment', as: 'product_segment', relatedName: 'product_generic_blocks' }),
    background_photo_id: fk({ to: 'V3_Photo', as: 'background_photo', relatedName: 'backgrounded_product_generic_blocks' }),
  }
}
export class V3_ProductBookBlock extends QurttyModel {
  static modelName = 'V3_ProductBookBlock'

  static fields = {
    product_segment_id: fk({ to: 'V3_ProductSegment', as: 'product_segment', relatedName: 'product_book_blocks' }),
    background_photo_id: fk({ to: 'V3_Photo', as: 'background_photo', relatedName: 'backgrounded_product_book_blocks' }),
  }
}

export class V3_ProductSegment extends QurttyModel {
  static modelName = 'V3_ProductSegment'

  static fields = {
    website_nav_group_id: fk({ to: 'V3_WebsiteNavGroup', as: 'website_nav_group', relatedName: 'product_segments' }),
    background_photo_id: fk({ to: 'V3_Photo', as: 'background_photo', relatedName: 'backgrounded_product_segments' }),
  }

  static INCLUDES_PARAMS = {
    product_banner_blocks: V3_ProductBannerBlock.INCLUDES_PARAMS,
    product_html_blocks: {},
    product_generic_blocks: { generic_block_slides: V3_GenericBlockSlide.INCLUDES_PARAMS },
    product_book_blocks: { generic_block_slides: V3_GenericBlockSlide.INCLUDES_PARAMS },
    generic_block_slides: V3_GenericBlockSlide.INCLUDES_PARAMS,
  }

  getBloggable () {
    return this.getPolymorphicHolder('bloggable')
  }
}

export class V3_SubproductGroup extends QurttyModel {
  static modelName = 'V3_SubproductGroup'

  static fields = {
    product_id: fk({ to: 'V3_Product', as: 'product', relatedName: 'subproduct_groups' }),
  }
}

export class V3_ProductGrouping extends QurttyModel {
  static modelName = 'V3_ProductGrouping'

  static fields = {
    subproduct_group_id: fk({ to: 'V3_SubproductGroup', as: 'subproduct_group', relatedName: 'product_groupings' }),
    product_id: fk({ to: 'V3_Product', as: 'product', relatedName: 'product_groupings' }),
  }
}

export class V3_FormTerm extends QurttyModel {
  static modelName = 'V3_FormTerm'
  static fields = {
    webpage_contact_us_section_style_id: fk({ to: 'V3_WebpageContactUsSectionStyle', as: 'webpage_contact_us_section_style', relatedName: 'form_terms' }),
  }
}

export class V3_BillingPlan extends QurttyModel {
  static modelName = 'V3_BillingPlan'

  static fields = {
  }
}

export class V3_Product extends QurttyModel {
  static modelName = 'V3_Product'
  static fields = {
  }

  static INCLUDES_PARAMS = {
    photos: {},
    linked_values: {},
    requestabilities: {},
    particle_requests: {},
    purchasabilities: {},
    subproduct_groups: {},
    reverse_product_groupings: {},
    particle_purchases: {},
    extension_entity: { values: {} },
    category_exposures: {},
  }

  photo_ids: number[]

  getPhotos () {
    return this.photo_ids.map((id) => session.V3_Photo.withId(id))
  }

  getExternalPages () {
    return this.getPolymorphicMany('V3_ExternalPage', 'holder').toModelArray()
  }

  getExtensionEntity () {
    return this.getPolymorphicOne('V3_Eav_Entity', 'extended_particle')
  }

  getProductSegments () {
    // return filter(session.V3_ProductSegment, val => val.bloggable_type === 'V3::Product' && val.bloggable_id === this.id).toModelArray()
    return polymorphicAssoiciations(this, 'V3_ProductSegment', 'V3::Product', 'bloggable')
  }

  getPhotoablePhotoParticipations () {
    return [
      ...this.getAssociation('product_in_project_photo_participations'),
      ...this.getAssociation('product_in_doc_photo_participations'),
    ]
  }

  getBillingPlans () {
    return this.getPolymorphicMany('V3_BillingPlan', 'particle').toModelArray()
  }
}

export class V3_ProductSnapshot extends QurttyModel {
  static modelName = 'V3_ProductSnapshot'
  static fields = {
  }
}

export const productModelRegister = (orm) => {
  orm.register(
    V3_Product,
    V3_BillingPlan,
    V3_SubproductGroup,
    V3_ProductGrouping,
    V3_ProductSegment,
    V3_ProductHtmlBlock,
    V3_ProductBannerBlock,
    V3_ProductGenericBlock,
    V3_ProductBookBlock,
    V3_GenericBlockActionButton,
    V3_BannerBlockPhoto,
    V3_GenericBlockSlide,
    V3_FormTerm,
    V3_BlockArrow,
    V3_ProductSnapshot,
  )
}
