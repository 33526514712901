import { get } from 'lodash'
import {
  fk, oneToOne,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class Member extends QurttyModel {
  static modelName = 'Member'

  static fields = {
    profile_photo_id: fk({ to: 'V3_Photo', as: 'profile_photo', relatedName: 'member_profile_photo' }),
    v3_employment_id: fk({ to: 'V3_Employment', as: 'employment', relatedName: 'employee_account' }),
    hand4_v5_primary_member_card_id: fk({ to: 'V5_MemberCard', as: 'hand4_v5_primary_member_card', relatedName: '_member' }),
    hand4_v3_primary_website_project_id: oneToOne({ to: 'V3_Project', as: 'hand4_v3_primary_website_project', relatedName: '_member' }),
  }

  getPhotoUrl (urlName) {
    return get(this, 'hand4_v5_primary_member_card.m2m_avatar.' + urlName)
  }
}

export class V3_MemberName extends QurttyModel {
  static modelName = 'V3_MemberName'

  static fields = {
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'member_names' }),
  }
}

export const memberModelRegister = (orm) => {
  orm.register(Member, V3_MemberName)
}
