const factsheets: any = [
  {
    id: 177,
    date: '2018-10-01',
    status: 'draft',
    original_filename: null,
    original_sheetname: null,
    fund_size: '317630000.0',
    insurance_company_fund_launch_date: '2007-03-26',
    launch_date_format: null,
    fund_management_charge: '3.0',
    fund_name_en: 'Allianz Oriental Income Fund',
    fund_name_zh_hk: '安聯東方入息基金',
    fund_name_zh_cn: null,
    fund_name_ja: null,
    fund_name_ko: null,
    fund_objective_en: 'Long-term capital growth by investing in Asia Pacific equity and bond markets.',
    fund_objective_zh_hk: '投資於亞太區股票及債券市場，以達致長期資本增值。',
    fund_objective_zh_cn: null,
    fund_objective_ja: null,
    fund_objective_ko: null,
    errors: {
      risk_ratings: [
        "can't be blank",
      ],
    },
    fund: {
      id: 3,
      fundhouse: {
        created_at: '2021-11-05T17:35:38.940+08:00',
        domain: null,
        fund_sector_id: null,
        id: 8,
        name_en: 'Allianz Global Investors Asia Pacific Limited',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '安聯環球投資亞太有限公司',
        photo_participations_count: 0,
        slug: '33bc6f9c-e3b5-47d1-84ae-a402750c6583',
        type: 'V3::Fund::Fundhouse',
        updated_at: '2021-11-05T17:35:38.940+08:00',
        v5_company_id: 46,
        model_class: 'V3::Fund::Fundhouse',
        are_funds_valid: true,
        are_children_valid: false,
      },
    },
    template: {
      id: 1,
      name: 'Template 1',
      url: null,
    },
    fund_size_currency: {
      created_at: '2020-08-24T10:40:53.371+08:00',
      id: 1,
      name_en: 'USD',
      name_ja: null,
      name_ko: null,
      name_zh_cn: null,
      name_zh_hk: '美元',
      updated_at: '2020-08-24T10:40:53.385+08:00',
      model_class: 'V3::Currency',
    },
    factsheet_breakdowns: [
      {
        id: 953,
        value: '93.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Asset',
        display_order: 1,
        breakdown: {
          created_at: '2021-11-11T16:04:15.975+08:00',
          id: 4,
          name_en: 'Equity',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '股票',
          updated_at: '2021-11-11T16:04:15.975+08:00',
          model_class: 'V3::Fund::Asset',
        },
      },
      {
        id: 955,
        value: '22.2',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 1,
        breakdown: {
          created_at: '2021-11-11T16:04:16.062+08:00',
          id: 36,
          name_en: 'Korea',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '韓國',
          updated_at: '2021-11-11T16:04:16.062+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 956,
        value: '13.8',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 2,
        breakdown: {
          created_at: '2021-11-08T10:35:32.847+08:00',
          id: 13,
          name_en: 'Japan',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '日本',
          updated_at: '2021-11-08T10:35:32.847+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 957,
        value: '12.5',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 3,
        breakdown: {
          created_at: '2021-11-08T10:35:31.237+08:00',
          id: 11,
          name_en: 'China',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '中國',
          updated_at: '2021-11-08T10:35:31.237+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 958,
        value: '12.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 4,
        breakdown: {
          created_at: '2021-11-08T10:35:33.122+08:00',
          id: 14,
          name_en: 'Taiwan',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '台灣',
          updated_at: '2021-11-08T10:35:33.122+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 943,
        value: '9.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 1,
        breakdown: {
          created_at: '2021-11-11T16:04:15.628+08:00',
          domain: null,
          id: 87,
          name_en: 'KOH YOUNG TECHNOLOGY INC',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'KOH YOUNG TECHNOLOGY INC',
          photo_participations_count: 0,
          slug: '243f34e7-4165-4d51-ac90-8e1f8f4c3c2b',
          type: null,
          updated_at: '2021-11-11T16:04:15.628+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 959,
        value: '8.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 5,
        breakdown: {
          created_at: '2021-11-11T16:04:16.148+08:00',
          id: 37,
          name_en: 'New Zealand',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '新西蘭',
          updated_at: '2021-11-11T16:04:16.148+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 960,
        value: '7.9',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 6,
        breakdown: {
          created_at: '2021-11-11T16:04:16.171+08:00',
          id: 38,
          name_en: 'Singapore',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '新加坡',
          updated_at: '2021-11-11T16:04:16.171+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 944,
        value: '7.8',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 2,
        breakdown: {
          created_at: '2021-11-11T16:04:15.669+08:00',
          domain: null,
          id: 88,
          name_en: 'MAINFREIGHT LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'MAINFREIGHT LTD',
          photo_participations_count: 0,
          slug: 'b921285e-12e4-4434-a8f6-2e13f4986e25',
          type: null,
          updated_at: '2021-11-11T16:04:15.669+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 966,
        value: '6.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 12,
        breakdown: {
          created_at: '2021-11-11T16:04:16.393+08:00',
          id: 42,
          name_en: 'Liquidity',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '流動資金',
          updated_at: '2021-11-11T16:04:16.393+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 954,
        value: '6.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Asset',
        display_order: 2,
        breakdown: {
          created_at: '2021-11-11T16:04:15.998+08:00',
          id: 5,
          name_en: 'Liquidity',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '流動資金',
          updated_at: '2021-11-11T16:04:15.998+08:00',
          model_class: 'V3::Fund::Asset',
        },
      },
      {
        id: 961,
        value: '6.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 7,
        breakdown: {
          created_at: '2021-11-08T10:39:58.057+08:00',
          id: 34,
          name_en: 'Hong Kong',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '香港',
          updated_at: '2021-11-08T10:39:58.057+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 945,
        value: '5.2',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 3,
        breakdown: {
          created_at: '2021-11-11T16:04:15.704+08:00',
          domain: null,
          id: 89,
          name_en: 'SHOWA DENKO K K',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'SHOA DENKO K KW',
          photo_participations_count: 0,
          slug: 'ebee45c2-5e7d-44be-b35b-17da12fd0f40',
          type: null,
          updated_at: '2021-11-11T16:04:15.704+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 962,
        value: '4.5',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 8,
        breakdown: {
          created_at: '2021-11-11T16:04:16.300+08:00',
          id: 39,
          name_en: 'Australia',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '澳洲',
          updated_at: '2021-11-11T16:04:16.300+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 946,
        value: '4.2',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 4,
        breakdown: {
          created_at: '2021-11-11T16:04:15.731+08:00',
          domain: null,
          id: 90,
          name_en: 'CHINA CONSTRUCTION BANK-H',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'CHINA CONSTRUCTION BANK-H',
          photo_participations_count: 0,
          slug: '8e975978-159d-4ea1-a97e-8717842da8d3',
          type: null,
          updated_at: '2021-11-11T16:04:15.731+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 947,
        value: '4.1',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 5,
        breakdown: {
          created_at: '2021-11-11T16:04:15.755+08:00',
          domain: null,
          id: 91,
          name_en: 'POSCO CHEMTECH CO LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'POSCO CHEMTECH CO LTD',
          photo_participations_count: 0,
          slug: 'd8c3968f-711e-47c4-ac0a-4ba382a52615',
          type: null,
          updated_at: '2021-11-11T16:04:15.755+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 948,
        value: '4.0',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 6,
        breakdown: {
          created_at: '2021-11-11T16:04:15.784+08:00',
          domain: null,
          id: 92,
          name_en: 'VENTURE CORP LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'VENTURE CORP LTD',
          photo_participations_count: 0,
          slug: '88547a8f-6081-4d3a-b3e1-74f3de7f91ae',
          type: null,
          updated_at: '2021-11-11T16:04:15.784+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 949,
        value: '3.9',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 7,
        breakdown: {
          created_at: '2021-11-11T16:04:15.811+08:00',
          domain: null,
          id: 93,
          name_en: 'LASERTEC CORP',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'LASERTEC CORP',
          photo_participations_count: 0,
          slug: 'c74c64d8-dcb2-4691-8bd7-29b1e47f477c',
          type: null,
          updated_at: '2021-11-11T16:04:15.811+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 950,
        value: '3.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 8,
        breakdown: {
          created_at: '2021-11-11T16:04:15.847+08:00',
          domain: null,
          id: 94,
          name_en: 'EO TECHNICS CO LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'EO TECHNICS CO LTD',
          photo_participations_count: 0,
          slug: '04dc13c4-d678-444f-a57e-559f6737a94f',
          type: null,
          updated_at: '2021-11-11T16:04:15.847+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 951,
        value: '3.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 9,
        breakdown: {
          created_at: '2021-11-11T16:04:15.869+08:00',
          domain: null,
          id: 95,
          name_en: 'AVICHINA INDUSTRY & TECH-H',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'AVICHINA INDUSTRY & TECH-H',
          photo_participations_count: 0,
          slug: 'dae67e1d-ec2f-428f-8995-8523ea702c00',
          type: null,
          updated_at: '2021-11-11T16:04:15.869+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 952,
        value: '3.1',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 10,
        breakdown: {
          created_at: '2021-11-11T16:04:15.891+08:00',
          domain: null,
          id: 96,
          name_en: 'GLOBALWAFERS CO LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'GLOBALWAFERS CO LTD',
          photo_participations_count: 0,
          slug: '7d95fa69-7b0c-40e3-8520-ae6e2a248d1a',
          type: null,
          updated_at: '2021-11-11T16:04:15.891+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 963,
        value: '2.5',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 9,
        breakdown: {
          created_at: '2021-11-11T16:04:16.330+08:00',
          id: 40,
          name_en: 'Vietnam',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '越南',
          updated_at: '2021-11-11T16:04:16.330+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 965,
        value: '1.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 11,
        breakdown: {
          created_at: '2021-11-08T10:39:58.100+08:00',
          id: 35,
          name_en: 'Others',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '其他',
          updated_at: '2021-11-08T10:39:58.100+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 964,
        value: '1.0',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 10,
        breakdown: {
          created_at: '2021-11-11T16:04:16.354+08:00',
          id: 41,
          name_en: 'Malaysia',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '馬來西亞',
          updated_at: '2021-11-11T16:04:16.354+08:00',
          model_class: 'V3::Country',
        },
      },
    ],
    footnotes: [
      {
        content_en: 'Source: Allianz Global Investors Asia Pacific Limited, in US dollar, NAV to NAV, dividend reinvested.',
        content_ja: null,
        content_ko: null,
        content_zh_cn: null,
        content_zh_hk: '資料來源：安聯環球投資亞太有限公司，以美元、資產淨值及股息再投資計算。',
        created_at: '2021-11-11T16:52:31.351+08:00',
        display_order: 0,
        factsheet_id: 177,
        id: 130,
        symbol: '^',
        updated_at: '2021-11-11T16:52:31.351+08:00',
        model_class: 'V3::Fund::Footnote',
        sections: [],
        section_footnotes: [],
      },
      {
        content_en: 'The underlying fund of Allianz Oriental Income Fund is managed by Allianz Global Investors Asia Pacific Limited and was launched on December 2005. All data that relates to performance illustrate the historical performance of the underlying fund into which the investment-linked funds offered under the Investment-Linked Plan of LifeTrack Assurance Series are invested. The performance figures shown may not reflect the actual performance of the investment-linked fund. Likewise all holdings and breakdown shown relates to that of the underlying funds. The source of all non-specified information referred to above are provided by the specified Fund Manager.',
        content_ja: null,
        content_ko: null,
        content_zh_cn: null,
        content_zh_hk: '注意：安聯東方入息基金的相關基金由安聯環球投資亞太有限公司管理並於2005年12月成立。上述資料顯示「人生共步」儲蓄投資人壽系列內投資相連計劃下投資相連基金所投資的相關基金的過往業績，可能並不反映影子基金的實際表現及其持股及分佈。以上資料來源由指定基金經理提供。',
        created_at: '2021-11-11T16:52:31.493+08:00',
        display_order: 0,
        factsheet_id: 177,
        id: 131,
        symbol: '*',
        updated_at: '2021-11-11T16:52:31.493+08:00',
        model_class: 'V3::Fund::Footnote',
        sections: [],
        section_footnotes: [],
      },
      {
        content_en: 'With effect from 17 July, 2012, the English name of the investment-linked fund Allianz RCM Oriental Income Fund has been changed to Allianz Oriental Income Fund.',
        content_ja: null,
        content_ko: null,
        content_zh_cn: null,
        content_zh_hk: '由2012年7月17日起，投資相連基金的英文名稱已經由Allianz RCM Oriental Income Fund 更改為 Allianz Oriental Income Fund。',
        created_at: '2021-11-11T16:52:31.512+08:00',
        display_order: 0,
        factsheet_id: 177,
        id: 132,
        symbol: '#',
        updated_at: '2021-11-11T16:52:31.512+08:00',
        model_class: 'V3::Fund::Footnote',
        sections: [],
        section_footnotes: [],
      },
    ],
    performances: [
      {
        benchmark_title_en: 'MSCI AC Asia Pacific Index',
        benchmark_title_ja: null,
        benchmark_title_ko: null,
        benchmark_title_zh_cn: null,
        benchmark_title_zh_hk: '摩根士丹利綜合亞洲太平洋指數',
        created_at: '2021-11-11T16:10:54.091+08:00',
        factsheet_id: 177,
        fund_title_en: 'Allianz Oriental Income',
        fund_title_ja: null,
        fund_title_ko: null,
        fund_title_zh_cn: null,
        fund_title_zh_hk: '安聯東方入息基金',
        id: 86,
        updated_at: '2021-11-11T16:52:31.539+08:00',
        model_class: 'V3::Fund::Performance',
        performance_items: [
          {
            benchmark_score: '-2.87',
            created_at: '2021-11-11T16:10:54.172+08:00',
            display_order: 0,
            fund_score: '-5.63',
            id: 77,
            label_en: 'YTD',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '年初至今',
            performance_id: 86,
            updated_at: '2021-11-11T16:10:54.172+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '26.96',
            created_at: '2021-11-11T16:46:45.798+08:00',
            display_order: 1,
            fund_score: '25.31',
            id: 83,
            label_en: '2017',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2017',
            performance_id: 86,
            updated_at: '2021-11-11T16:46:46.132+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '4.89',
            created_at: '2021-11-11T16:46:45.839+08:00',
            display_order: 2,
            fund_score: '2.13',
            id: 84,
            label_en: '2016',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2016',
            performance_id: 86,
            updated_at: '2021-11-11T16:46:46.151+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '-1.96',
            created_at: '2021-11-11T16:46:45.877+08:00',
            display_order: 3,
            fund_score: '-0.79',
            id: 85,
            label_en: '2015',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2015',
            performance_id: 86,
            updated_at: '2021-11-11T16:46:46.163+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '0.0',
            created_at: '2021-11-11T16:46:45.923+08:00',
            display_order: 4,
            fund_score: '-6.11',
            id: 86,
            label_en: '2014',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2014',
            performance_id: 86,
            updated_at: '2021-11-11T16:46:46.175+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '11.97',
            created_at: '2021-11-11T16:46:46.062+08:00',
            display_order: 5,
            fund_score: '21.94',
            id: 87,
            label_en: '2013',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2013',
            performance_id: 86,
            updated_at: '2021-11-11T16:46:46.186+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '87.67',
            created_at: '2021-11-11T16:10:54.194+08:00',
            display_order: 6,
            fund_score: '103.44',
            id: 78,
            label_en: 'Since Launch',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '自成立至今',
            performance_id: 86,
            updated_at: '2021-11-11T16:46:46.199+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
        ],
      },
    ],
    fund_asset_values: [
      {
        currency: {
          name_en: 'USD',
        },
        value: '534.73',
      },
      {
        currency: {
          name_en: 'HK',
        },
        value: '534.73',
      },
      null,
    ],
    risk_ratings: [],
    fund_sections: [
      {
        created_at: '2021-11-05T17:35:40.162+08:00',
        fund_id: 3,
        id: 66,
        name_en: '(Fund name)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(基金名稱)',
        section_id: 22,
        updated_at: '2021-11-05T17:35:40.186+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.126+08:00',
        fund_id: 3,
        id: 65,
        name_en: '(Benchmark Performance title)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(市場指標名稱)',
        section_id: 21,
        updated_at: '2021-11-05T17:35:40.146+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.087+08:00',
        fund_id: 3,
        id: 64,
        name_en: '(Fund Performance title)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(基金表現名稱)',
        section_id: 20,
        updated_at: '2021-11-05T17:35:40.109+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.046+08:00',
        fund_id: 3,
        id: 63,
        name_en: '(Investment Objective content)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(基金目標內文)',
        section_id: 19,
        updated_at: '2021-11-05T17:35:40.071+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.002+08:00',
        fund_id: 3,
        id: 62,
        name_en: '',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '',
        section_id: 18,
        updated_at: '2021-11-05T17:35:40.028+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.958+08:00',
        fund_id: 3,
        id: 61,
        name_en: 'Currency Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '貨幣分佈',
        section_id: 17,
        updated_at: '2021-11-05T17:35:39.984+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.922+08:00',
        fund_id: 3,
        id: 60,
        name_en: 'Asset Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '資產分佈',
        section_id: 16,
        updated_at: '2021-11-05T17:35:39.943+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.874+08:00',
        fund_id: 3,
        id: 59,
        name_en: 'Credit Rating (S&P / Moody) Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '信用評級（標準普爾／穆迪）分佈',
        section_id: 15,
        updated_at: '2021-11-05T17:35:39.904+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.824+08:00',
        fund_id: 3,
        id: 58,
        name_en: 'Sector Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '行業分佈',
        section_id: 14,
        updated_at: '2021-11-05T17:35:39.850+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.773+08:00',
        fund_id: 3,
        id: 57,
        name_en: 'Geographical Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '地區分佈',
        section_id: 13,
        updated_at: '2021-11-05T17:35:39.802+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.728+08:00',
        fund_id: 3,
        id: 56,
        name_en: 'Top 10 Holdings',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '持股最多之公司',
        section_id: 12,
        updated_at: '2021-11-05T17:35:39.755+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.682+08:00',
        fund_id: 3,
        id: 55,
        name_en: 'The Underlying Fund of Investment-linked Fund Performance',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的相關基金表現',
        section_id: 11,
        updated_at: '2021-11-05T17:35:39.708+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.641+08:00',
        fund_id: 3,
        id: 54,
        name_en: 'The Investment Objective of the Underlying Fund of Investment-linked Fund:',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的相關基金目標 :',
        section_id: 10,
        updated_at: '2021-11-05T17:35:39.665+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.594+08:00',
        fund_id: 3,
        id: 53,
        name_en: 'Key Facts',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '重要資料',
        section_id: 9,
        updated_at: '2021-11-05T17:35:39.624+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.541+08:00',
        fund_id: 3,
        id: 52,
        name_en: 'The Underlying Fund of Investment-linked Fund Details',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的相關基金資料',
        section_id: 8,
        updated_at: '2021-11-05T17:35:39.568+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.498+08:00',
        fund_id: 3,
        id: 51,
        name_en: 'Net Asset Value per unit',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '單位資產淨值',
        section_id: 7,
        updated_at: '2021-11-05T17:35:39.524+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.453+08:00',
        fund_id: 3,
        id: 50,
        name_en: 'Underlying Fund Size',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '相關基金資產',
        section_id: 6,
        updated_at: '2021-11-05T17:35:39.481+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.397+08:00',
        fund_id: 3,
        id: 49,
        name_en: 'Underlying Fund Manager',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '相關基金經理',
        section_id: 5,
        updated_at: '2021-11-05T17:35:39.430+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.349+08:00',
        fund_id: 3,
        id: 48,
        name_en: 'Launch Date',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '成立日期',
        section_id: 4,
        updated_at: '2021-11-05T17:35:39.379+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.308+08:00',
        fund_id: 3,
        id: 47,
        name_en: 'The Investment-linked Fund Management Charge',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的管理費',
        section_id: 3,
        updated_at: '2021-11-05T17:35:39.331+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.258+08:00',
        fund_id: 3,
        id: 46,
        name_en: 'Investment-linked Fund Currency',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金貨幣',
        section_id: 2,
        updated_at: '2021-11-05T17:35:39.287+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:39.143+08:00',
        fund_id: 3,
        id: 45,
        name_en: '',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '',
        section_id: 1,
        updated_at: '2021-11-05T17:35:39.167+08:00',
        model_class: 'V3::Fund::FundSection',
      },
    ],
  },
  {
    id: 178,
    date: '2018-10-01',
    status: 'draft',
    original_filename: null,
    original_sheetname: null,
    fund_size: '276880000.0',
    insurance_company_fund_launch_date: '2007-03-26',
    launch_date_format: null,
    fund_management_charge: '3.0',
    fund_name_en: 'Allianz Total Return Asian Equity Fund',
    fund_name_zh_hk: '安聯總回報亞洲股票基金',
    fund_name_zh_cn: null,
    fund_name_ja: null,
    fund_name_ko: null,
    fund_objective_en: 'Long-term capital growth by investing in the equity markets of the Republic of Korea, Taiwan, Thailand, Hong Kong, Malaysia, Indonesia, the Philippines, Singapore and the People’s Republic of China (the “PRC”).',
    fund_objective_zh_hk: '投資於大韓民國、台灣、泰國、香港、馬來西亞、印尼、菲律賓、新加坡及中華人民共和國（「中國」）股票市場，以達致長期資本增值。',
    fund_objective_zh_cn: null,
    fund_objective_ja: null,
    fund_objective_ko: null,
    errors: {
      risk_ratings: [
        "can't be blank",
      ],
    },
    fund: {
      id: 4,
      fundhouse: {
        created_at: '2021-11-05T17:35:38.940+08:00',
        domain: null,
        fund_sector_id: null,
        id: 8,
        name_en: 'Allianz Global Investors Asia Pacific Limited',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '安聯環球投資亞太有限公司',
        photo_participations_count: 0,
        slug: '33bc6f9c-e3b5-47d1-84ae-a402750c6583',
        type: 'V3::Fund::Fundhouse',
        updated_at: '2021-11-05T17:35:38.940+08:00',
        v5_company_id: 46,
        model_class: 'V3::Fund::Fundhouse',
        are_funds_valid: true,
        are_children_valid: false,
      },
    },
    template: {
      id: 1,
      name: 'Template 1',
      url: null,
    },
    fund_size_currency: {
      created_at: '2020-08-24T10:40:53.371+08:00',
      id: 1,
      name_en: 'USD',
      name_ja: null,
      name_ko: null,
      name_zh_cn: null,
      name_zh_hk: '美元',
      updated_at: '2020-08-24T10:40:53.385+08:00',
      model_class: 'V3::Currency',
    },
    factsheet_breakdowns: [
      {
        id: 988,
        value: '38.5',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 1,
        breakdown: {
          created_at: '2021-11-08T10:35:31.237+08:00',
          id: 11,
          name_en: 'China',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '中國',
          updated_at: '2021-11-08T10:35:31.237+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 977,
        value: '21.1',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 1,
        breakdown: {
          created_at: '2021-11-08T10:35:31.208+08:00',
          id: 10,
          name_en: 'Financials',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '金融',
          updated_at: '2021-11-08T10:35:31.208+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 978,
        value: '15.4',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 2,
        breakdown: {
          created_at: '2021-11-11T16:04:16.885+08:00',
          id: 41,
          name_en: 'Cons. Disc.',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '非主要消費',
          updated_at: '2021-11-11T16:04:16.885+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 979,
        value: '12.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 3,
        breakdown: {
          created_at: '2021-11-11T16:04:16.907+08:00',
          id: 42,
          name_en: 'Cons. Staples',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '基本消費',
          updated_at: '2021-11-11T16:04:16.907+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 980,
        value: '11.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 4,
        breakdown: {
          created_at: '2021-11-11T16:04:16.932+08:00',
          id: 43,
          name_en: 'Communication Services',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '傳訊服務',
          updated_at: '2021-11-11T16:04:16.932+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 989,
        value: '9.4',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 2,
        breakdown: {
          created_at: '2021-11-08T10:35:33.122+08:00',
          id: 14,
          name_en: 'Taiwan',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '台灣',
          updated_at: '2021-11-08T10:35:33.122+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 990,
        value: '8.8',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 3,
        breakdown: {
          created_at: '2021-11-08T10:35:32.640+08:00',
          id: 12,
          name_en: 'India',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '印度',
          updated_at: '2021-11-08T10:35:32.640+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 981,
        value: '8.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 5,
        breakdown: {
          created_at: '2021-11-11T16:04:16.959+08:00',
          id: 44,
          name_en: 'Industrials',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '工業',
          updated_at: '2021-11-11T16:04:16.959+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 982,
        value: '7.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 6,
        breakdown: {
          created_at: '2021-11-11T16:04:16.981+08:00',
          id: 45,
          name_en: 'IT',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '資訊科技',
          updated_at: '2021-11-11T16:04:16.981+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 967,
        value: '7.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 1,
        breakdown: {
          created_at: '2021-11-11T16:04:16.550+08:00',
          domain: null,
          id: 97,
          name_en: 'TENCENT HOLDINGS LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'TENCENT HOLDINGS LTD',
          photo_participations_count: 0,
          slug: '9c03082a-21a6-4554-8a01-38b833f2537d',
          type: null,
          updated_at: '2021-11-11T16:04:16.550+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 991,
        value: '7.0',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 4,
        breakdown: {
          created_at: '2021-11-08T10:39:58.057+08:00',
          id: 34,
          name_en: 'Hong Kong',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '香港',
          updated_at: '2021-11-08T10:39:58.057+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 999,
        value: '6.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 12,
        breakdown: {
          created_at: '2021-11-11T16:04:16.393+08:00',
          id: 42,
          name_en: 'Liquidity',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '流動資金',
          updated_at: '2021-11-11T16:04:16.393+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 987,
        value: '6.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 11,
        breakdown: {
          created_at: '2021-11-11T16:04:17.081+08:00',
          id: 48,
          name_en: 'Liquidity',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '流動資金',
          updated_at: '2021-11-11T16:04:17.081+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 992,
        value: '6.4',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 5,
        breakdown: {
          created_at: '2021-11-11T16:04:16.062+08:00',
          id: 36,
          name_en: 'Korea',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '韓國',
          updated_at: '2021-11-11T16:04:16.062+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 983,
        value: '5.9',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 7,
        breakdown: {
          created_at: '2021-11-08T10:39:57.390+08:00',
          id: 28,
          name_en: 'Energy',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '能源',
          updated_at: '2021-11-08T10:39:57.390+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 968,
        value: '5.9',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 2,
        breakdown: {
          created_at: '2021-11-11T16:04:16.573+08:00',
          domain: null,
          id: 98,
          name_en: 'PETROCHINA CO LTD-H',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'PETROCHINA CO LTD-H',
          photo_participations_count: 0,
          slug: '81aaad60-4694-4dcf-8350-2cf42ad075f4',
          type: null,
          updated_at: '2021-11-11T16:04:16.573+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 984,
        value: '5.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 8,
        breakdown: {
          created_at: '2021-11-11T16:04:17.019+08:00',
          id: 46,
          name_en: 'Real Estate',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '房地產',
          updated_at: '2021-11-11T16:04:17.019+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 969,
        value: '5.5',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 3,
        breakdown: {
          created_at: '2021-11-11T16:04:16.595+08:00',
          domain: null,
          id: 99,
          name_en: 'HDFC BANK LTD-ADR',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'HDFC BANK LTD-ADR',
          photo_participations_count: 0,
          slug: 'c0203a6d-1f19-4c07-b422-9e1477e92d87',
          type: null,
          updated_at: '2021-11-11T16:04:16.595+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 970,
        value: '5.3',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 4,
        breakdown: {
          created_at: '2021-11-11T16:04:16.618+08:00',
          domain: null,
          id: 100,
          name_en: 'AIA GROUP LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'AIA GROUP LTD',
          photo_participations_count: 0,
          slug: 'ddc6144a-c5ef-4e08-9bf6-699261560514',
          type: null,
          updated_at: '2021-11-11T16:04:16.618+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 971,
        value: '4.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 5,
        breakdown: {
          created_at: '2021-11-11T16:04:16.645+08:00',
          domain: null,
          id: 101,
          name_en: 'ALIBABA GROUP HOLDING-SP ADR',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'ALIBABA GROUP HOLDING-SP ADR',
          photo_participations_count: 0,
          slug: '07992dc6-962a-427b-acf7-f96e0e9b3a2f',
          type: null,
          updated_at: '2021-11-11T16:04:16.645+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 993,
        value: '4.4',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 6,
        breakdown: {
          created_at: '2021-11-11T16:04:16.171+08:00',
          id: 38,
          name_en: 'Singapore',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '新加坡',
          updated_at: '2021-11-11T16:04:16.171+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 972,
        value: '4.0',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 6,
        breakdown: {
          created_at: '2021-11-11T16:04:16.675+08:00',
          domain: null,
          id: 102,
          name_en: 'TELEKOMUNIKASI INDONESIA PER',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'TELEKOMUNIKASI INDONESIA PER',
          photo_participations_count: 0,
          slug: '9ee89df8-1b58-4cfa-8240-bc24fc126390',
          type: null,
          updated_at: '2021-11-11T16:04:16.675+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 998,
        value: '4.0',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 11,
        breakdown: {
          created_at: '2021-11-08T10:39:58.100+08:00',
          id: 35,
          name_en: 'Others',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '其他',
          updated_at: '2021-11-08T10:39:58.100+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 994,
        value: '4.0',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 7,
        breakdown: {
          created_at: '2021-11-11T16:04:17.228+08:00',
          id: 43,
          name_en: 'Indonesia',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '印尼',
          updated_at: '2021-11-11T16:04:17.228+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 973,
        value: '3.8',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 7,
        breakdown: {
          created_at: '2021-11-11T16:04:16.698+08:00',
          domain: null,
          id: 103,
          name_en: 'KWEICHOW MOUTAI CO LTD-A',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'KWEICHOW MOUTAI CO LTD-A',
          photo_participations_count: 0,
          slug: 'bd342627-6f5e-48a6-94fb-b51259b6a9b2',
          type: null,
          updated_at: '2021-11-11T16:04:16.698+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 995,
        value: '3.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 8,
        breakdown: {
          created_at: '2021-11-11T16:04:17.257+08:00',
          id: 44,
          name_en: 'Thailand',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '泰國',
          updated_at: '2021-11-11T16:04:17.257+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 974,
        value: '3.7',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 8,
        breakdown: {
          created_at: '2021-11-11T16:04:16.721+08:00',
          domain: null,
          id: 104,
          name_en: 'CENTRAL PATTANA PUB CO-FOREI',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'CENTRAL PATTANA PUB CO-FOREI',
          photo_participations_count: 0,
          slug: 'b55e5c52-5fb5-4ad2-be47-9172fcadb250',
          type: null,
          updated_at: '2021-11-11T16:04:16.721+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 975,
        value: '3.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 9,
        breakdown: {
          created_at: '2021-11-11T16:04:16.746+08:00',
          domain: null,
          id: 105,
          name_en: 'MGM CHINA HOLDINGS LTD',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'MGM CHINA HOLDINGS LTD',
          photo_participations_count: 0,
          slug: '12f03366-bd94-4ccb-b896-e741b6b78245',
          type: null,
          updated_at: '2021-11-11T16:04:16.746+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 996,
        value: '3.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 9,
        breakdown: {
          created_at: '2021-11-11T16:04:17.290+08:00',
          id: 45,
          name_en: 'Philippines',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '菲律賓',
          updated_at: '2021-11-11T16:04:17.290+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 997,
        value: '3.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Country',
        display_order: 10,
        breakdown: {
          created_at: '2021-11-11T16:04:17.309+08:00',
          id: 46,
          name_en: 'Macao',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '澳門',
          updated_at: '2021-11-11T16:04:17.309+08:00',
          model_class: 'V3::Country',
        },
      },
      {
        id: 976,
        value: '3.1',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Company',
        display_order: 10,
        breakdown: {
          created_at: '2021-11-11T16:04:16.776+08:00',
          domain: null,
          id: 106,
          name_en: 'CHINA MENGNIU DAIRY CO',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: 'CHINA MENGNIU DAIRY CO',
          photo_participations_count: 0,
          slug: '964434c8-7907-4b27-a1e1-42002a7a7a19',
          type: null,
          updated_at: '2021-11-11T16:04:16.776+08:00',
          v5_company_id: null,
          model_class: 'V3::Company',
          v5_company: null,
        },
      },
      {
        id: 985,
        value: '2.9',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 9,
        breakdown: {
          created_at: '2021-11-11T16:04:17.041+08:00',
          id: 47,
          name_en: 'Healthcare',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '健康護理',
          updated_at: '2021-11-11T16:04:17.041+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
      {
        id: 986,
        value: '2.6',
        unit: '%',
        detail: null,
        breakdown_type: 'V3::Fund::Sector',
        display_order: 10,
        breakdown: {
          created_at: '2021-11-08T10:39:57.544+08:00',
          id: 33,
          name_en: 'Others',
          name_ja: null,
          name_ko: null,
          name_zh_cn: null,
          name_zh_hk: '其他',
          updated_at: '2021-11-08T10:39:57.544+08:00',
          model_class: 'V3::Fund::Sector',
        },
      },
    ],
    footnotes: [
      {
        content_en: 'Source: Allianz Global Investors Asia Pacific Limited, in US dollar, NAV to NAV, dividend reinvested.',
        content_ja: null,
        content_ko: null,
        content_zh_cn: null,
        content_zh_hk: '資料來源：安聯環球投資亞太有限公司，以美元、資產淨值及股息再投資計算。',
        created_at: '2021-11-11T16:52:31.670+08:00',
        display_order: 0,
        factsheet_id: 178,
        id: 133,
        symbol: '^',
        updated_at: '2021-11-11T16:52:31.670+08:00',
        model_class: 'V3::Fund::Footnote',
        sections: [],
        section_footnotes: [],
      },
      {
        content_en: 'The underlying fund of Allianz Total Return Asian Equity Fund is managed by Allianz Global Investors  Asia Pacific Limited and was launched on October 2005. All data that relates to performance illustrate the historical performance of the underlying fund into which the investment-linked funds offered under the Investment-Linked Plan of LifeTrack Assurance Series are invested. The performance figures shown may not reflect the actual performance of the investment-linked fund. Likewise all holdings and breakdown shown relates to that of the underlying funds. The source of all non-specified information referred to above are provided by the specified Fund Manager.',
        content_ja: null,
        content_ko: null,
        content_zh_cn: null,
        content_zh_hk: '注意：安聯總回報亞洲股票基金的相關基金由安聯環球投資亞太有限公司管理並於2005年10月成立。上述資料顯示「人生共步」儲蓄投資人壽系列內投資相連計劃下投資相連基金所投資的相關基金的過往業績，可能並不反映影子基金的實際表現及其持股及分佈。以上資料來源由指定基金經理提供。',
        created_at: '2021-11-11T16:52:31.687+08:00',
        display_order: 0,
        factsheet_id: 178,
        id: 134,
        symbol: '*',
        updated_at: '2021-11-11T16:52:31.687+08:00',
        model_class: 'V3::Fund::Footnote',
        sections: [],
        section_footnotes: [],
      },
      {
        content_en: 'With effect from 17 July, 2012, the English name of the investment-linked fund Allianz RCM Total Return Asian Equity Fund has been changed to Allianz Total Return Asian Equity Fund.',
        content_ja: null,
        content_ko: null,
        content_zh_cn: null,
        content_zh_hk: '由2012年7月17日起，投資相連基金的英文名稱已經由Allianz RCM Total Return Asian Equity Fund更改為Allianz Total Return Asian Equity Fund。',
        created_at: '2021-11-11T16:52:31.704+08:00',
        display_order: 0,
        factsheet_id: 178,
        id: 135,
        symbol: '#',
        updated_at: '2021-11-11T16:52:31.704+08:00',
        model_class: 'V3::Fund::Footnote',
        sections: [],
        section_footnotes: [],
      },
    ],
    performances: [
      {
        benchmark_title_en: 'Benchmark',
        benchmark_title_ja: null,
        benchmark_title_ko: null,
        benchmark_title_zh_cn: null,
        benchmark_title_zh_hk: '市場指標',
        created_at: '2021-11-11T16:10:55.994+08:00',
        factsheet_id: 178,
        fund_title_en: 'Allianz Total Return Asian Equity',
        fund_title_ja: null,
        fund_title_ko: null,
        fund_title_zh_cn: null,
        fund_title_zh_hk: '安聯總回報亞洲股票基金',
        id: 87,
        updated_at: '2021-11-11T16:52:31.721+08:00',
        model_class: 'V3::Fund::Performance',
        performance_items: [
          {
            benchmark_score: '-6.26',
            created_at: '2021-11-11T16:10:56.021+08:00',
            display_order: 0,
            fund_score: '-10.96',
            id: 79,
            label_en: 'YTD',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '年初至今',
            performance_id: 87,
            updated_at: '2021-11-11T16:10:56.021+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '37.13',
            created_at: '2021-11-11T16:46:46.965+08:00',
            display_order: 1,
            fund_score: '35.21',
            id: 88,
            label_en: '2017',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2017',
            performance_id: 87,
            updated_at: '2021-11-11T16:46:47.163+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '5.44',
            created_at: '2021-11-11T16:46:47.006+08:00',
            display_order: 2,
            fund_score: '-2.99',
            id: 89,
            label_en: '2016',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2016',
            performance_id: 87,
            updated_at: '2021-11-11T16:46:47.173+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '-10.0',
            created_at: '2021-11-11T16:46:47.046+08:00',
            display_order: 3,
            fund_score: '-8.69',
            id: 90,
            label_en: '2015',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2015',
            performance_id: 87,
            updated_at: '2021-11-11T16:46:47.184+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '3.16',
            created_at: '2021-11-11T16:46:47.083+08:00',
            display_order: 4,
            fund_score: '4.87',
            id: 91,
            label_en: '2014',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2014',
            performance_id: 87,
            updated_at: '2021-11-11T16:46:47.198+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '3.76',
            created_at: '2021-11-11T16:46:47.114+08:00',
            display_order: 5,
            fund_score: '11.15',
            id: 92,
            label_en: '2013',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '2013',
            performance_id: 87,
            updated_at: '2021-11-11T16:46:47.209+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
          {
            benchmark_score: '185.41',
            created_at: '2021-11-11T16:10:56.041+08:00',
            display_order: 6,
            fund_score: '157.43',
            id: 80,
            label_en: 'Since Launch',
            label_ja: null,
            label_ko: null,
            label_zh_cn: null,
            label_zh_hk: '自成立至今',
            performance_id: 87,
            updated_at: '2021-11-11T16:46:47.220+08:00',
            model_class: 'V3::Fund::PerformanceItem',
          },
        ],
      },
    ],
    fund_asset_values: [
      null,
      null,
    ],
    risk_ratings: [],
    fund_sections: [
      {
        created_at: '2021-11-05T17:35:41.500+08:00',
        fund_id: 4,
        id: 88,
        name_en: '(Fund name)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(基金名稱)',
        section_id: 22,
        updated_at: '2021-11-05T17:35:41.526+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.449+08:00',
        fund_id: 4,
        id: 87,
        name_en: '(Benchmark Performance title)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(市場指標名稱)',
        section_id: 21,
        updated_at: '2021-11-05T17:35:41.480+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.397+08:00',
        fund_id: 4,
        id: 86,
        name_en: '(Fund Performance title)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(基金表現名稱)',
        section_id: 20,
        updated_at: '2021-11-05T17:35:41.429+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.292+08:00',
        fund_id: 4,
        id: 85,
        name_en: '(Investment Objective content)',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '(基金目標內文)',
        section_id: 19,
        updated_at: '2021-11-05T17:35:41.320+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.241+08:00',
        fund_id: 4,
        id: 84,
        name_en: '',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '',
        section_id: 18,
        updated_at: '2021-11-05T17:35:41.269+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.181+08:00',
        fund_id: 4,
        id: 83,
        name_en: 'Currency Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '貨幣分佈',
        section_id: 17,
        updated_at: '2021-11-05T17:35:41.217+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.136+08:00',
        fund_id: 4,
        id: 82,
        name_en: 'Asset Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '資產分佈',
        section_id: 16,
        updated_at: '2021-11-05T17:35:41.160+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.089+08:00',
        fund_id: 4,
        id: 81,
        name_en: 'Credit Rating (S&P / Moody) Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '信用評級（標準普爾／穆迪）分佈',
        section_id: 15,
        updated_at: '2021-11-05T17:35:41.115+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.050+08:00',
        fund_id: 4,
        id: 80,
        name_en: 'Sector Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '行業分佈',
        section_id: 14,
        updated_at: '2021-11-05T17:35:41.072+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:41.008+08:00',
        fund_id: 4,
        id: 79,
        name_en: 'Geographical Allocation',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '地區分佈',
        section_id: 13,
        updated_at: '2021-11-05T17:35:41.030+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.965+08:00',
        fund_id: 4,
        id: 78,
        name_en: 'Top 10 Holdings',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '持股最多之公司',
        section_id: 12,
        updated_at: '2021-11-05T17:35:40.989+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.924+08:00',
        fund_id: 4,
        id: 77,
        name_en: 'The Underlying Fund of Investment-linked Fund Performance',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的相關基金表現',
        section_id: 11,
        updated_at: '2021-11-05T17:35:40.949+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.875+08:00',
        fund_id: 4,
        id: 76,
        name_en: 'The Investment Objective of the Underlying Fund of Investment-linked Fund:',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的相關基金目標 :',
        section_id: 10,
        updated_at: '2021-11-05T17:35:40.902+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.834+08:00',
        fund_id: 4,
        id: 75,
        name_en: 'Key Facts',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '重要資料',
        section_id: 9,
        updated_at: '2021-11-05T17:35:40.857+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.792+08:00',
        fund_id: 4,
        id: 74,
        name_en: 'The Underlying Fund of Investment-linked Fund Details',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的相關基金資料',
        section_id: 8,
        updated_at: '2021-11-05T17:35:40.818+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.752+08:00',
        fund_id: 4,
        id: 73,
        name_en: 'Net Asset Value per unit',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '單位資產淨值',
        section_id: 7,
        updated_at: '2021-11-05T17:35:40.774+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.700+08:00',
        fund_id: 4,
        id: 72,
        name_en: 'Underlying Fund Size',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '相關基金資產',
        section_id: 6,
        updated_at: '2021-11-05T17:35:40.735+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.659+08:00',
        fund_id: 4,
        id: 71,
        name_en: 'Underlying Fund Manager',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '相關基金經理',
        section_id: 5,
        updated_at: '2021-11-05T17:35:40.682+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.620+08:00',
        fund_id: 4,
        id: 70,
        name_en: 'Launch Date',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '成立日期',
        section_id: 4,
        updated_at: '2021-11-05T17:35:40.643+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.572+08:00',
        fund_id: 4,
        id: 69,
        name_en: 'The Investment-linked Fund Management Charge',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金的管理費',
        section_id: 3,
        updated_at: '2021-11-05T17:35:40.597+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.526+08:00',
        fund_id: 4,
        id: 68,
        name_en: 'Investment-linked Fund Currency',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '投資相連基金貨幣',
        section_id: 2,
        updated_at: '2021-11-05T17:35:40.552+08:00',
        model_class: 'V3::Fund::FundSection',
      },
      {
        created_at: '2021-11-05T17:35:40.480+08:00',
        fund_id: 4,
        id: 67,
        name_en: '',
        name_ja: null,
        name_ko: null,
        name_zh_cn: null,
        name_zh_hk: '',
        section_id: 1,
        updated_at: '2021-11-05T17:35:40.507+08:00',
        model_class: 'V3::Fund::FundSection',
      },
    ],
  },
]

export default factsheets
