import {
  fk,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_Version extends QurttyModel {
  modelUrl = 'paper_trail/versions'
  static modelName = 'V3_Version'
  static fields = {}
}

export class ΔV3_PhotoΔVersion extends V3_Version {
  static modelName = 'ΔV3_PhotoΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Photo', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_MemberCardΔVersion extends V3_Version {
  static modelName = 'ΔV3_MemberCardΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_MemberCard', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_CompanyCardΔVersion extends V3_Version {
  static modelName = 'ΔV3_CompanyCardΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_CompanyCard', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_BusinessCardΔVersion extends V3_Version {
  static modelName = 'ΔV3_BusinessCardΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_BusinessCard', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_MembershipΔVersion extends V3_Version {
  static modelName = 'ΔV3_MembershipΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Membership', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_ProductΔVersion extends V3_Version {
  static modelName = 'ΔV3_ProductΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Product', as: 'item', relatedName: 'versions' }),
  }
}

export class ΔV3_WebsiteΔVersion extends V3_Version {
  static modelName = 'ΔV3_WebsiteΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Website', as: 'item', relatedName: 'versions' }),
  }
}

export class ΔV3_PhoneΔVersion extends V3_Version {
  static modelName = 'ΔV3_PhoneΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Phone', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_AddressΔVersion extends V3_Version {
  static modelName = 'ΔV3_AddressΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Address', as: 'item', relatedName: 'versions' }),
  }
}
export class ΔV3_EmailΔVersion extends V3_Version {
  static modelName = 'ΔV3_EmailΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Email', as: 'item', relatedName: 'versions' }),
  }
}

export class ΔV3_Eav_ValueΔVersion extends V3_Version {
  static modelName = 'ΔV3_Eav_ValueΔVersion'
  static fields = {
    item_id: fk({ to: 'V3_Eav_Value', as: 'item', relatedName: 'versions' }),
  }
}

export const versionModelRegister = (orm) => {
  orm.register(
    V3_Version,
    ΔV3_PhotoΔVersion,
    ΔV3_MemberCardΔVersion, ΔV3_CompanyCardΔVersion, ΔV3_BusinessCardΔVersion,
    ΔV3_ProductΔVersion,
    ΔV3_WebsiteΔVersion,
    ΔV3_PhoneΔVersion, ΔV3_AddressΔVersion, ΔV3_EmailΔVersion,
    ΔV3_Eav_ValueΔVersion,
  )
}
