import { flatMap } from 'lodash'

import { polymorphicAssoiciations } from '../../helpers/polymorphicHelper'
import { session } from '../schemas'
import { QurttyModel } from './modelDefault'

export class V3_Blog extends QurttyModel {
  static modelName = 'V3_Blog'
  static fields = {
  }

  static INCLUDES_PARAMS = {
    photos: {},
    linked_values: {},
    requestabilities: {},
    particle_requests: {},
    extension_entity: { values: {} },
  }

  photo_ids: number[]

  getPhotos () {
    return this.photo_ids.map((id) => session.V3_Photo.withId(id))
  }

  getExternalPages () {
    return this.getPolymorphicMany('V3_ExternalPage', 'holder').toModelArray()
  }

  getExtensionEntity () {
    return this.getPolymorphicOne('V3_Eav_Entity', 'extended_particle')
  }

  getProductSegments () {
    return polymorphicAssoiciations(this, 'V3_ProductSegment', 'V3::Blog', 'bloggable')
  }

  getSubscriptionDemands () {
    return polymorphicAssoiciations(this, 'V3_SubscriptionDemand', 'V3::Blog', 'subscribable')
  }

  getSubscriptionApplications (appStatus = ['pending_approval', 'approved']) {
    const demands = this.getSubscriptionDemands()
    const extractApplication = (demand) => {
      return demand.subscription_plan.subscription_applications.toModelArray().filter((a) => appStatus.includes(a.status))
    }
    return flatMap(demands, extractApplication)
  }

  getSubscriptioinDeals () {
    const demands = this.getSubscriptionDemands()
    return flatMap(demands, (demand) => {
      return demand.subscription_plan.subscription_deals.toModelArray()
    })
  }

  getPhotoablePhotoParticipations () {
    return [
      ...this.getAssociation('blog_in_project_photo_participations'),
      ...this.getAssociation('blog_in_doc_photo_participations'),
    ]
  }
}

export const blogModelRegister = (orm) => {
  orm.register(V3_Blog)
}
