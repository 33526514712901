/*
 * Here is second level of API calling. Those are not for developer to export as action.
 * They are just helper function.
 */

import { AxiosRequestConfig } from 'axios'
import { insertNestedResponseToOrm, modelClassToModelName } from '../orm/ormHelper'
import { ormState, session } from '../orm/schemas'
import * as qurttyApi from './api'

/**
 *This function purpose is getting the particle list for reducer and convert raw data to model.
 *DoubleAPIChecking will check the do particle need to update and ask server for the particle ORM doesn't have it.
 *DoubleAPIChecking have 2 part:
 *First part will only check if anythings have to update or download from server. Then pass the list to second part. If not then return the particle llst and not go for second part.
 *Second part will ask server for the data and insert those into ORM.
 * @param  {String} resourcesUrl        The URL for GET request
 * @param  {Object} [firstApiParam={}]  The parameter of checking need update API call.
 * @param  {Object} [secondApiParam={}] The parameter of checking what particle need to update. If this parameter is null, then will use the firstApiParam.
 * @param  {Object} [options={}]        If you need to sort the returned particle list, pass options={noArray: true} and use the api in https://redux-orm.github.io/redux-orm/QuerySet.html
 * @return {Promise}                     Promise of the model instance array
 *
 */
export const doubleAPIChecking = (resourcesUrl, firstApiParam: any = {}, secondApiParam: any = {}, options: any = {}, config: AxiosRequestConfig = {}) => {
  const firstParam = {
    ...firstApiParam,
    only_attributes: ['id', 'updated_at'],
  }

  return new Promise((resolve, reject) => {
    qurttyApi.getResourcesAPI(resourcesUrl, firstParam, config).then((resp) => {
      const json = resp.data
      const key = Object.keys(json)[0]
      const particleList = json[key]

      if (!Array.isArray(particleList)) {
        return
      }

      const modelName = modelClassToModelName(particleList[0]?.model_class)
      if (!modelName) { return }

      const ormStateModelList = ormState[modelName]
      const existingParticleList = particleList.filter((particle) => {
        return ormStateModelList.items.includes(particle.id) && (particle.updated_at === ormStateModelList.itemsById[particle.id].updated_at)
      }).map((particle) => {
        return particle.id
      })
      let secondParam: any = {}
      if (secondApiParam) {
        secondParam = {
          ...secondApiParam,
          not: existingParticleList,
        }
      } else {
        secondParam = {
          ...firstApiParam,
          not: existingParticleList,
        }
      }
      secondParam.page = firstApiParam.page || 1
      secondParam.per_page = firstApiParam.per_page || 20

      // FIXME: This must be fix since not here to define how much page of data to get.

      if (particleList.length === existingParticleList.length) {
        const particleIdList = particleList.map((p) => p.id)
        let particleReturnList = session[modelName].filter((rec) => particleIdList.includes(rec.id)).orderBy('display_order', 'asc')
        if (!options.noArray) {
          particleReturnList = particleReturnList.toModelArray()
        }
        resolve({ particleList: particleReturnList, response: resp })
      } else {
        qurttyApi.getResourcesAPI(resourcesUrl, secondParam, config).then((resp) => {
          const json = resp.data
          insertNestedResponseToOrm(json, session)
          const particleIdList = particleList.map((p) => p.id)
          let particleReturnList = session[modelName].filter((rec) => particleIdList.includes(rec.id)).orderBy('display_order', 'asc')
          if (!options.noArray) {
            particleReturnList = particleReturnList.toModelArray()
          }
          resolve({ particleList: particleReturnList, response: resp })
        })
      }
    }, (reject) => {
      return (reject)
    })
  })
}

export const getResources = (resourcesUrl, params = {}, options: any = { responseProcessor: null, noArray: false }, config: AxiosRequestConfig = {}, theSession = session) => {
  return qurttyApi.getResourcesAPI(resourcesUrl, params, config).then((resp) => {
    const key = Object.keys(resp.data)[0]
    const records = resp.data[key] || []
    const ids = records.map((rec) => rec.id)
    const modelName = modelClassToModelName(records[0]?.model_class)
    if (options.responseProcessor) {
      options.responseProcessor(resp.data)
    } else {
      insertNestedResponseToOrm(resp.data, theSession)
    }
    if (!modelName) { return [] }
    let particleReturnList = theSession[modelName].filter((ormRec) => ids.includes(ormRec.id)).orderBy('display_order', 'asc')
    if (!options.noArray) {
      particleReturnList = particleReturnList.toModelArray()
    }
    return particleReturnList
  })
}

export const getSingleResource = (resourcesUrl, id, params = {}, config: AxiosRequestConfig = {}, options: any = { responseProcessor: null }, theSession = session) => {
  return qurttyApi.getSingleResourceAPI(resourcesUrl, id, params, config).then((resp) => {
    const key = Object.keys(resp.data)[0]
    const record = resp.data[key]
    const modelName = modelClassToModelName(record?.model_class)
    if (options.responseProcessor) {
      options.responseProcessor(resp.data)
    } else {
      insertNestedResponseToOrm(resp.data, theSession)
    }
    if (!modelName) { return }
    const realId = record?.id
    const ormRecord = theSession[modelName].withId(realId)
    return ormRecord
  })
}

export const getAccountMember = (memberID, params = {}) => {
  const apiParam = {
    ...params,
    _with_roles: true,
    _with_cards: true,
    _with_card_parts: true,
    _with_company: true,
    _with_asset_pool: true,
  }

  return qurttyApi.getUserAccountAPI(memberID, apiParam).then((json) => {
    insertNestedResponseToOrm(json, session)
    const member = session.Member.withId(memberID)
    return member
  })
}

// Refresh the certain card. Return a promise for refresh the array or somethings else
export const refreshSingleMemberCard = (cardID, params = {}) => {
  const apiParam = {
    ...params,
    _with_roles: true,
    _with_cards: true,
    _with_card_parts: true,
    _with_company: true,
  }
  qurttyApi.getSingleMemberCardAPI(cardID, apiParam).then((json) => {
    insertNestedResponseToOrm(json, session)
    const memberCard = session.V3_MemberCard.withId(cardID)
    return memberCard
  })
}
