import {
  fk,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_Address extends QurttyModel {
  modelUrl = 'card/addresses'
  static modelName = 'V3_Address'
  static fields = {
  }
}
export class V3_ParsedAddress extends QurttyModel {
  static modelName = 'V3_ParsedAddress'
  static fields = {
    address_id: fk({ to: 'V3_Address', as: 'address', relatedName: 'parsed_addresses' }),
  }
}

export class V3_HkPostal_Address extends QurttyModel {
  static modelName = 'V3_HkPostal_Address'
  static fields = {
    card_address_id: fk({ to: 'V3_Address', as: 'address', relatedName: 'hk_postal_addresses' }),
    district_id: fk({ to: 'V3_HkPostal_District', as: 'district', relatedName: 'hk_postal_addresses' }),
    street_id: fk({ to: 'V3_HkPostal_Street', as: 'street', relatedName: 'hk_postal_addresses' }),
    street_number_id: fk({ to: 'V3_HkPostal_StreetNumber', as: 'street_number', relatedName: 'hk_postal_addresses' }),
    estate_id: fk({ to: 'V3_HkPostal_Estate', as: 'estate', relatedName: 'hk_postal_addresses' }),
    phase_id: fk({ to: 'V3_HkPostal_Phase', as: 'phase', relatedName: 'hk_postal_addresses' }),
    building_id: fk({ to: 'V3_HkPostal_Building', as: 'building', relatedName: 'hk_postal_addresses' }),
    floor_id: fk({ to: 'V3_HkPostal_Floor', as: 'floor', relatedName: 'hk_postal_addresses' }),
    unit_id: fk({ to: 'V3_HkPostal_Unit', as: 'unit', relatedName: 'hk_postal_addresses' }),
  }
}

export class V3_Email extends QurttyModel {
  modelUrl = 'card/emails'
  static modelName = 'V3_Email'
  static fields = {
  }
}

export class V3_Phone extends QurttyModel {
  modelUrl = 'card/phones'
  static modelName = 'V3_Phone'
  static fields = {
  }
}

export class V3_Website extends QurttyModel {
  modelUrl = 'card/websites'
  static modelName = 'V3_Website'
  static fields = {}
}

export class V3_ParticleCategoryExposure extends QurttyModel {
  static modelName = 'V3_ParticleCategoryExposure'
  static fields = {}
}

export const exposuresModelRegister = (orm) => {
  orm.register(
    V3_Phone,
    V3_ParsedAddress, V3_HkPostal_Address, V3_Address,
    V3_Email,
    V3_Website, V3_ParticleCategoryExposure,
  )
}
