import * as qurttyApi from './api/api'
import * as qurttyApiHelper from './api/apiHelper'
import authHelper from './api/authHelper'
import axios from './api/axiosSetting'
import cssHelper from './api/cssHelper'
import OrmUtils from './helpers/OrmUtils'
import cookiesHelper from './helpers/cookiesHelper'
import createQurttyPopupAuthHelper from './helpers/createQurttyPopupAuthHelper'
import * as projectHelper from './helpers/projectHelper'
import * as routerHelper from './helpers/routerHelper'
import utils from './helpers/utils'
import seeds from './orm/data/seeds'
import { orm } from './orm/models'
import { QurttyModel } from './orm/models/modelDefault'
import * as ormHelper from './orm/ormHelper'
import { session } from './orm/schemas'

console.log('axios.defaults.baseURL: ', `${process.env.REACT_APP_API_BASE_URL}`)
axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`

export {
  qurttyApi,
  qurttyApiHelper,
  ormHelper,
  authHelper,
  projectHelper,
  routerHelper,
  cookiesHelper,
  cssHelper,
  createQurttyPopupAuthHelper,
  session,
  orm,
  axios,
  seeds,
  utils,
  OrmUtils,
  QurttyModel,
}
