import { CircularProgress } from '@mui/material'
import React from 'react'

const Loading = () => {
  return (
    <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.3)', display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default Loading
