import { css } from '@emotion/css/macro'
import { sortBy } from 'lodash'
import React from 'react'
import { Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import scaleHelper from '../../../../utils/ScaleHelper'
import { currencyAbbr, getFundSectionName } from '../../../utils/TemplateUtils'

import './index.css'

const Template2Paper = ({ factsheet, locale }) => {
  const getLineData = (performance) => {
    return performance.performance_items.filter((item) => !isNaN(item[`label_${locale}`])).sort((itemA, itemB) => {
      return parseInt(itemA[`label_${locale}`]) - parseInt(itemB[`label_${locale}`])
    }).map((item) => {
      return {
        name: item[`label_${locale}`],
        [performance[`fund_title_${locale}`]]: Number(item.fund_score),
        [performance[`benchmark_title_${locale}`]]: Number(item.benchmark_score),
      }
    })
  }

  const getPies = (factsheetBreakdowns) => {
    const pies = {};

    [
      ['company', 'V3::Company'], ['geo', 'V3::Country'], ['sector', 'V3::Fund::Sector'], ['rating', 'V3::Fund::CreditRating'], ['asset', 'V3::Fund::Asset'], ['currency', 'V3::Currency'],
    ].forEach(function (type) {
      const data = factsheetBreakdowns.filter(function (fundBkd) {
        return fundBkd.breakdown_type === type[1]
      }).sort(function (a, b) {
        return (a.display_order && b.display_order) ? a.display_order - b.display_order : a.id - b.id
      }).map(function (fundBkd, i) {
        return fundBkd.value
      })
      pies[type[0]] = {}
      pies[type[0]].data = data.map(function (v, i) {
        return { key: i + 1, value: parseInt(v) }
      })
    })
    return pies
  }
  const classes = useStyles(scaleHelper.screenWidthNeedScale('420mm'))

  return (
    <div className={classes.factsheetPaper} >
      <div className={`FactsheetTemplate2 page print ${classes.factsheetPage}`}>
        <div className='page_title'>
          <h1>
            {factsheet[`fund_name_${locale}`]}
          </h1>
          <span className='date'>
            {factsheet.date}
          </span>
        </div>
        <div className='section detail'>
          <div className='section_content'>
            <div className='basic'>
              <table>
                <tbody>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('fund-size', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.fund_size_currency[`name_${locale}`] === 'USD' ? 'US$' : (factsheet.fund_size_currency[`name_${locale}`] === 'EUR' ? 'EUR€' : 'HK$')} {currencyAbbr(factsheet.fund_size, locale)}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('net-asset-value-2', locale)}
                    </td>
                    <td className='value'>
                      <div>
                        {
                          factsheet.fund_asset_values.filter(Boolean).map((assetVal, idx) => (
                            <span key={idx}>
                              { idx === 1 && ' ( ' }
                              <span className='currency-symbol'>{assetVal.currency[`name_${locale}`] === 'USD' ? 'US$' : 'HK$'}</span>
                              <span>{assetVal.value}</span>
                              { factsheet.fund_asset_values.filter(Boolean)[idx + 1] !== undefined ? (idx !== 0 && '/') : ' )'}
                            </span>
                          ))
                        }
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('since-launch', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.insurance_company_fund_launch_date}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('fund-manager', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.fund.fundhouse[`name_${locale}`]}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('unit', locale)}
                    </td>
                    <td className='value'>
                      <div>unit</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('currency', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.fund_size_currency[`name_${locale}`] === 'USD' ? 'US$' : (factsheet.fund_size_currency[`name_${locale}`] === 'EUR' ? 'EUR€' : 'HK$')}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('management-tee', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.fund_management_charge} %</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('isin-code', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.isin_code}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('bloomberg-code', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.bloomberg_code}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className='key'>
                      {getFundSectionName('risk-level', locale)}
                    </td>
                    <td className='value'>
                      <div>{factsheet.risk_level}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='performance_chart'>
              <div className='subtitle'>{getFundSectionName('cumulative-performance', locale)}</div>
              <div className='line'>
                <ResponsiveContainer width='100%' height='100%' >
                  <LineChart
                    width={500}
                    height={300}
                    data={getLineData(factsheet.performances[0])}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <XAxis dataKey='name' padding={{ left: 30, right: 40 }} />
                    <YAxis tickFormatter={(tick) => `${tick}%`} />
                    <Legend wrapperStyle={{ bottom: '1px' }} />
                    <Line type='monotone' dataKey={factsheet.performances[0][`fund_title_${locale}`]} stroke='rgb(0, 142, 175)' />
                    <Line type='monotone' dataKey={factsheet.performances[0][`benchmark_title_${locale}`]} stroke='rgb(0, 0, 0)' />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
        <div className='section detail'>
          <div className='section_content'>
            <div className='investment_objective'>
              <table>
                <tbody>
                  <tr>
                    <th className={locale}>{getFundSectionName('investment-objective', locale)}</th>
                  </tr>
                  <tr>
                    <td className={locale}>{factsheet[`fund_objective_${locale}`]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='top_holdings'>
              <table>
                <tbody>
                  <tr>
                    <th colSpan={3}>{getFundSectionName('breakdown-by-company', locale)}</th>
                  </tr>
                  {
                    sortBy(factsheet.factsheet_breakdowns.filter((fcstBrkd) => fcstBrkd.breakdown_type === 'V3::Company'), 'display_order').map((fcstBrkd, idx) => (
                      <tr key={idx}>
                        <td>{fcstBrkd.breakdown[`name_${locale}`]}</td>
                        <td>{fcstBrkd.breakdown.country}</td>
                        <td>{fcstBrkd.value ? `${parseFloat(fcstBrkd.value).toFixed(2)} %` : 'N/A'}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='section detail'>
          <div className='section_content'>
            <div className='cumulative_performance'>
              <div className='subtitle'>{getFundSectionName('cumulative-performance', locale)}</div>
              {
                factsheet.performances.map((perform, perfIdx) => (
                  <table key={perfIdx}>
                    <tbody>
                      <tr>
                        <td></td>
                        {
                          sortBy(perform.performance_items, 'display_order').map((item, itemIdx) => (
                            <td key={itemIdx}>
                              <span>{item[`label_${locale}`]}</span>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td>
                          <span>{perform[`fund_title_${locale}`]}</span>
                        </td>
                        {
                          sortBy(perform.performance_items, 'display_order').map((item, itemIdx) => (
                            <td key={itemIdx}>{item.fund_score ? `${parseFloat(item.fund_score).toFixed(2)} %` : 'N/A'}</td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td>
                          <span>{perform[`benchmark_title_${locale}`]}</span>
                        </td>
                        {
                          sortBy(perform.performance_items, 'display_order').map((item, itemIdx) => (
                            <td key={itemIdx}>{item.benchmark_score ? `${parseFloat(item.benchmark_score).toFixed(2)} %` : 'N/A'}</td>
                          ))
                        }
                      </tr>
                    </tbody>
                  </table>
                ))
              }
            </div>
          </div>
        </div>
        <div className='allocations'>
          {
            [
              ['geo', 'V3::Country'], ['sector', 'V3::Fund::Sector'], ['rating', 'V3::Fund::CreditRating'], ['asset', 'V3::Fund::Asset'], ['currency', 'V3::Currency'],
            ].map((type) => {
              if (factsheet.factsheet_breakdowns.filter((fcstBrkd) => fcstBrkd.breakdown_type === type[1]).length === 0) { return null }
              const piesData = getPies(factsheet.factsheet_breakdowns)[type[0]].data

              return (
                <div key={type[0]} className='allocation'>
                  <div className='subtitle'>{getFundSectionName('breakdown-by-' + type[0], locale)}</div>
                  <div className='pie'>
                    <ResponsiveContainer width='100%' height='100%'>
                      <PieChart>
                        <Pie
                          cx={'40%'}
                          cy={'50%'}
                          outerRadius={100}
                          fill='rgba(0, 142, 175, .8)'
                          dataKey='value'
                          data={piesData}
                          paddingAngle={5}
                          label={(entry) => entry.key}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                  <table>
                    <tbody>
                      {
                        sortBy(factsheet.factsheet_breakdowns.filter((fcstBrkd) => fcstBrkd.breakdown_type === type[1]), 'display_order').map((fcstBrkd, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{fcstBrkd.breakdown[`name_${locale}`]}</td>
                            <td>{parseFloat(fcstBrkd.value).toFixed(2)} %</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            })
          }
        </div>
        <div className='footnote'>
          {
            sortBy(factsheet.footnotes, 'display_order').map((ftnt, idx) => (
              <table key={idx}>
                <tbody>
                  <tr>
                    <td>{ftnt.symbol}</td>
                    <td>{ftnt[`content_${locale}`]}</td>
                  </tr>
                </tbody>
              </table>
            ))
          }
        </div>
      </div>
    </div>
  )
}

function useStyles (mobileRatio) {
  return {
    factsheetPaper: css({
      height: 'calc((0px + 592mm) * 0.6)',
      marginBottom: '20px',
      '@media print': {
        height: 'auto',
        marginBottom: '0 !important',
      },
      '@media screen and (max-width: 834px)': {
        height: `calc(592mm * ${mobileRatio})`,
        width: `calc(420mm * ${mobileRatio})`,

        '& .page': {
          transform: `scale(${mobileRatio}) !important`,
        },
      },
    }),
    factsheetPage: css({
      '&&&': {
        transformOrigin: 'top left',
        transform: 'scale(0.6)',
        overflow: 'hidden',
        '@media print': {
          transform: 'scale(1)',
        },
      },
    }),
  }
}

export default Template2Paper
