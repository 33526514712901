import { css } from '@emotion/css/macro'
import { sortBy } from 'lodash'
import React from 'react'
import NVD3Chart from 'react-nvd3'
import { Textfit } from 'react-textfit'
import scaleHelper from '../../../../utils/ScaleHelper'
import { currencyAbbr, getFundSectionName } from '../../../utils/TemplateUtils'

import './index.css'

const Template1Paper = ({ factsheet }) => {
  const pieOptions = {
    type: 'pieChart',
    height: 150,
    x: function (d) {
      return d.key
    },
    y: function (d) {
      return d.y
    },
    color: [
      '#39516C', '#405b79', '#476486',
      '#4d6e93', '#54789f', '#5d81aa',
      '#6a8bb0', '#6a8bb0', '#849fbe',
      '#91a9c5', '#9eb3cc', '#aabdd2',
      '#b7c7d9', '#c4d1e0', '#d1dbe7',
      '#dee5ed', '#ebeff4', '#f7f9fb',
    ],
    tooltip: {
      enabled: false,
    },
    showLabels: true,
    showLegend: false,
    duration: 500,
    labelThreshold: 0.005,
    //      labelSunbeamLayout: true,
    labelsOutside: true,
    labelType: 'key',
    growOnHover: false,
    dispatch: {
      renderEnd: function () {
        window.dispatchEvent(new Event('resize'))
      },
    },
  }

  const getPies = (factsheetBreakdowns) => {
    const pies = {};
    [
      ['company', 'V3::Company'], ['geo', 'V3::Country'], ['sector', 'V3::Fund::Sector'], ['rating', 'V3::Fund::CreditRating'], ['asset', 'V3::Fund::Asset'], ['currency', 'V3::Currency'],
    ].forEach(function (type) {
      const data = factsheetBreakdowns.filter(function (fundBkd) {
        return fundBkd.breakdown_type === type[1]
      }).sort(function (a, b) {
        return (a.display_order && b.display_order) ? a.display_order - b.display_order : a.id - b.id
      }).map(function (fundBkd, i) {
        return fundBkd.value
      })
      pies[type[0]] = {}
      pies[type[0]].data = data.map(function (v, i) {
        return { key: i + 1, y: v }
      })
    })
    return pies
  }

  const classes = useStyles(scaleHelper.screenWidthNeedScale('420mm'))

  return (
    <div className={classes.factsheetPaper} >
      <div className={`FactsheetTemplate1 page print ${classes.factsheetPage}`}>
        <h1 className='page-title'></h1>
        <div className='section intro'>
          <h1>
            {factsheet.fund_name_en}
              &nbsp;&nbsp;
            {factsheet.fund_name_zh_hk}
          </h1>
          <div className='section-content'>
            <div className='currency-notice'>
              <span>{getFundSectionName('fund-currency', 'en')} {getFundSectionName('fund-currency', 'zh_hk')}: </span>
              <span>USD, HKD </span>
              <span>美元, 港元 </span>
                &nbsp;&nbsp;
              <span>{getFundSectionName('management-charge', 'en')} {getFundSectionName('management-charge', 'zh_hk')}: </span>
              <span>每年 {parseFloat(factsheet.fund_management_charge).toFixed(2)}%p.a.</span>
            </div>
            <div className='launch-date'>
              <span>{getFundSectionName('launch-date', 'en')} {getFundSectionName('launch-date', 'zh_hk')}: </span>
              <span>{factsheet.insurance_company_fund_launch_date}</span>
            </div>
            <div className='objective en'>
              <h1>{getFundSectionName('objective', 'en')}</h1>
              <div className='objective-content'><Textfit mode='multi'>{factsheet.fund_objective_en}</Textfit></div>
            </div>
            <div className='objective cht'>
              <h1>{getFundSectionName('objective', 'zh_hk')}</h1>
              <div className='objective-content'><Textfit mode='multi'>{factsheet.fund_objective_zh_hk}</Textfit></div>
            </div>
          </div>
        </div>
        <div className='section detail'>
          <h1>{getFundSectionName('fund-detail', 'en')}&nbsp;&nbsp;&nbsp;{getFundSectionName('fund-detail', 'zh_hk')}</h1>
          <div className='section-content'>
            <div className='key-facts'>
              <table>
                <tbody>
                  <tr>
                    <th className='en'>{getFundSectionName('keyfacts', 'en')}</th>
                    <th className='cht'>{getFundSectionName('keyfacts', 'zh_hk')}</th>
                  </tr>
                  <tr>
                    <td>
                      <div className='key'>{getFundSectionName('fund-manager', 'en')}</div>
                      <div className='value'>{factsheet.fund.fundhouse.name_en}</div>
                    </td>
                    <td>
                      <div className='key'>{getFundSectionName('fund-manager', 'zh_hk')}</div>
                      <div className='value'>{factsheet.fund.fundhouse.name_zh_hk}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='key'>{getFundSectionName('fund-size', 'en')}</div>
                      <div className='value'>{factsheet.fund_size_currency.name_en === 'USD' ? 'US$' : (factsheet.fund_size_currency.name_en === 'EUR' ? 'EUR€' : 'HK$')} {currencyAbbr(factsheet.fund_size, 'en')}</div>
                    </td>
                    <td>
                      <div className='key'>{getFundSectionName('fund-size', 'zh_hk')}</div>
                      <div className='value'>{currencyAbbr(factsheet.fund_size, 'zh_hk')} {factsheet.fund_size_currency.name_zh_hk}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='key'>{getFundSectionName('net-asset-value', 'en')}</div>
                      {
                        factsheet.fund_asset_values.filter(Boolean).map((assetVal, idx) => (
                          <div className='value' key={idx}>
                            <span className='currency-symbol'>{assetVal.currency.name_en === 'USD' ? 'US$' : 'HK$'}</span>
                            <span>{assetVal.value}</span>
                          </div>
                        ))
                      }
                    </td>
                    <td>
                      <div className='key'>{getFundSectionName('net-asset-value', 'zh_hk')}</div>
                      {
                        factsheet.fund_asset_values.filter(Boolean).map((assetVal, idx) => (
                          <div className='value' key={idx}>
                            <span className='currency-symbol'>{assetVal.currency.name_en === 'USD' ? '美元' : '港元'}</span>
                            <span>{assetVal.value}</span>
                          </div>
                        ))
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='top-holdings'>
              <h1>{getFundSectionName('breakdown-by-company', 'en')} {getFundSectionName('breakdown-by-company', 'zh_hk')}</h1>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>Percentage 百份比 (%)</td>
                  </tr>
                  {
                    sortBy(factsheet.factsheet_breakdowns.filter((fcstBrkd) => fcstBrkd.breakdown_type === 'V3::Company'), 'display_order').map((fcstBrkd, idx) => (
                      <tr key={idx}>
                        <td>{fcstBrkd.breakdown.name_en}</td>
                        <td>{fcstBrkd.value ? parseFloat(fcstBrkd.value).toFixed(2) : 'N/A'}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className='performance'>
              <h1>{getFundSectionName('performance', 'en')}&nbsp;&nbsp;&nbsp;{getFundSectionName('performance', 'zh_hk')}</h1>
              {
                factsheet.performances.map((perform, perfIdx) => (
                  <table key={perfIdx}>
                    <tbody>
                      <tr>
                        <td></td>
                        {
                          sortBy(perform.performance_items, 'display_order').map((item, itemIdx) => (
                            <td key={itemIdx}>
                              <span>{item.label_en}<br /></span>
                              {isNaN(item.label_zh_hk as any) && <span>{item.label_zh_hk}<br /></span>}
                              <span>(%)</span>
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td>
                          <span>{perform.fund_title_en}<br /></span>
                          <span>{perform.fund_title_zh_hk}</span>
                        </td>
                        {
                          sortBy(perform.performance_items, 'display_order').map((item, itemIdx) => (
                            <td key={itemIdx}>{item.fund_score ? parseFloat(item.fund_score).toFixed(2) : 'N/A'}</td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td>
                          <span>{perform.benchmark_title_en}<br /></span>
                          <span>{perform.benchmark_title_zh_hk}</span>
                        </td>
                        {
                          sortBy(perform.performance_items, 'display_order').map((item, itemIdx) => (
                            <td key={itemIdx}>{item.benchmark_score ? parseFloat(item.benchmark_score).toFixed(2) : 'N/A'}</td>
                          ))
                        }
                      </tr>
                    </tbody>
                  </table>
                ))
              }
            </div>

            <div className='allocations'>
              {
                [
                  ['geo', 'V3::Country'], ['sector', 'V3::Fund::Sector'], ['rating', 'V3::Fund::CreditRating'], ['asset', 'V3::Fund::Asset'], ['currency', 'V3::Currency'],
                ].map((type) => {
                  if (factsheet.factsheet_breakdowns.filter((fcstBrkd) => fcstBrkd.breakdown_type === type[1]).length === 0) { return null }
                  return (
                    <div key={type[0]} className='allocation'>
                      <h1>{getFundSectionName('breakdown-by-' + type[0], 'en')} {getFundSectionName('breakdown-by-' + type[0], 'zh_hk')}</h1>
                      <div className='pie' style={{ transformOrigin: 'top center', transform: 'scale(1.8)' }}>
                        <NVD3Chart datum={getPies(factsheet.factsheet_breakdowns)[type[0]].data} {...pieOptions} />
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan={4}>Percentage 百份比 (%)</td>
                          </tr>
                          {
                            sortBy(factsheet.factsheet_breakdowns.filter((fcstBrkd) => fcstBrkd.breakdown_type === type[1]), 'display_order').map((fcstBrkd, idx) => (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{fcstBrkd.breakdown.name_en}</td>
                                <td>{fcstBrkd.breakdown.name_zh_hk || fcstBrkd.breakdown.name_en}</td>
                                <td>{parseFloat(fcstBrkd.value).toFixed(2)}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  )
                })
              }
            </div>

          </div>
        </div>
        <div className='footnote'>
          {
            sortBy(factsheet.footnotes, 'display_order').map((ftnt, idx) => (
              <table key={idx}>
                <tbody>
                  <tr>
                    <td>{ftnt.symbol}</td>
                    <td>{ftnt.content_en}</td>
                  </tr>
                  <tr>
                    <td>{ftnt.symbol}</td>
                    <td>{ftnt.content_zh_hk}</td>
                  </tr>
                </tbody>
              </table>
            ))
          }

        </div>
      </div>
    </div>
  )
}

function useStyles (mobileRatio) {
  return {
    factsheetPaper: css({
      height: 'calc(592mm * 0.6)',
      marginBottom: '20px',
      '@media print': {
        height: 'auto',
        marginBottom: '0 !important',
      },
      '@media screen and (max-width: 834px)': {
        height: `calc(592mm * ${mobileRatio})`,
        width: `calc(420mm * ${mobileRatio})`,

        '& .page': {
          transform: `scale(${mobileRatio}) !important`,
        },
      },
    }),
    factsheetPage: css({
      '&&&': {
        transformOrigin: 'top left',
        transform: 'scale(0.6)',
        overflow: 'hidden',
        '@media print': {
          transform: 'scale(1)',
        },
      },
    }),
  }
}

export default Template1Paper
