import { css } from '@emotion/css/macro'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PrintRoundedIcon from '@mui/icons-material/PrintRounded'
import { Button, Dialog, IconButton, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Toolbar, useTheme } from '@mui/material'
import React, { useState } from 'react'
import LANG from '../constants/LANG'
import userGuideImg from './user-guide.jpg'

interface TemplateControlBarProps {
  lang: string,
  setLang: (any) => any,
  template: number,
  setTemplate: (any) => any,
  setInstructionPanelOpen: (boolean) => void
}

const TemplateControlBar = ({
  lang = 'en',
  setLang,
  template = 0,
  setTemplate,
  setInstructionPanelOpen,
}: TemplateControlBarProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const opened = Boolean(anchorEl)
  const [userGuideDialogOpen, setUserGuideDialogOpen] = useState(false)

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const close = () => {
    setAnchorEl(null)
  }

  const onLangChange = (e) => {
    setLang(e.target.value)
  }

  const onChangeTemplate = (temp) => {
    setTemplate(temp)
    close()
  }

  const onUserGuideButtonClick = () => {
    setUserGuideDialogOpen(true)
  }

  return (
    <div className={classes.controlBar}>
      <div className={classes.left}>
        <IconButton className={`${classes.templateBtn} ${classes.menuBtn}`} size='small' onClick={() => {
          setInstructionPanelOpen(true)
        }}>
          <MenuRoundedIcon />
        </IconButton>
        <ToggleButtonGroup
          size='small'
          value={lang}
          exclusive
          onChange={onLangChange}
          aria-label='text alignment'
          classes={{ grouped: classes.toggleBtnGroupGrouped }}
        >
          {
            template === 2 && Object.keys(LANG).map((la, idx) => (
              <ToggleButton key={idx} value={la} classes={{ selected: classes.toggleBtnSelected }}>
                {la}
              </ToggleButton>
            ))
          }
        </ToggleButtonGroup>
      </div>

      <div className={css({ display: 'flex', alignItems: 'center' })}>
        <Button variant='contained' className={css({ '&&': { backgroundColor: 'var(--title-color)' } })} onClick={onUserGuideButtonClick}>User Guide</Button>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '.5em' }}>
        <IconButton className={classes.templateBtn} size='small' onClick={() => window.print()}>
          <PrintRoundedIcon />
        </IconButton>
        <IconButton className={classes.templateBtn} size='small' onClick={open}>
          <MoreHorizRoundedIcon />
        </IconButton>
      </div>

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={opened}
        onClose={close}
      >
        <MenuItem selected={template === 1} onClick={() => onChangeTemplate(1)} disabled={true}>
          Template 1
        </MenuItem>
        <MenuItem selected={template === 2} onClick={() => onChangeTemplate(2)}>
          Template 2
        </MenuItem>
      </Menu>
      <Dialog fullScreen open={userGuideDialogOpen} onClose={() => setUserGuideDialogOpen(false)}>
        <Toolbar>
          <IconButton onClick={() => setUserGuideDialogOpen(false)}>
            <CloseRoundedIcon />
          </IconButton>
        </Toolbar>
        <div className={css({ flex: '1 1 auto', overflow: 'auto' })}>
          <img className={css({ objectFit: 'contain', width: '100%' })} src={userGuideImg} />
        </div>
      </Dialog>
    </div>
  )
}

const useStyles = (theme) => ({
  controlBar: css({
    width: '100%',
    height: '4em',
    padding: '.1em 1em',
    margin: '1em auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',

    '@media screen and (max-width: 834px) ': {
      width: '100vw',
      height: 'auto',
      flexWrap: 'wrap',
      padding: '.2em',
    },
    '@media print': {
      display: 'none',
    },
  }),

  left: css({
    display: 'flex',
    justifyContent: 'start',
  }),

  menuBtn: css({
    display: 'none !important',
    '@media screen and (max-width: 834px) ': {
      display: 'inline-flex !important',
    },
  }),

  templateBtn: css({
    height: 'fit-content',
    alignSelf: 'center',
  }),

  toggleBtnSelected: css({
    borderColor: 'transparent !important',
  }),

  toggleBtnGroupGrouped: css({
    color: 'rgba(144, 144, 144, .8)',

    '&&&&': {
      margin: `${theme.spacing(1)}`,
      padding: `${theme.spacing(1.3)}`,
      fontWeight: 'bold',
      fontSize: '.9rem',
      borderColor: 'rgba(144, 144, 144, .2)',
      borderRadius: theme.shape.borderRadius + 'px',

      '&:first-of-type': {
        marginRight: 0,
      },
    },

  }),
})

export default TemplateControlBar
