import numeral from 'numeral'
import sectionGroups from '../../data/sectionGroups'

const getFundSectionName = (identifier, lo) => {
  const sectionRow = Object.values(sectionGroups).flat().find((row) => row[0] === identifier)
  if (!sectionRow) { return '' }
  return sectionRow[1][lo]
}

const currencyAbbr = (number, locale) => {
  switch (locale) {
    case 'en':
      if (number >= Math.pow(10, 6)) {
        return numeral(number / Math.pow(10, 6)).format('0,0.00') + ' m'
      } else {
        return numeral(number).format('0,0')
      }
    case 'zh_hk':
      if (number >= Math.pow(10, 8)) {
        return numeral(number / Math.pow(10, 8)).format('0,0.0000') + ' 億'
      } else if (number >= Math.pow(10, 6)) {
        return numeral(number / Math.pow(10, 4)).format('0,0') + ' 萬'
      } else {
        return numeral(number).format('0,0') + ''
      }
    default:
      break
  }
}

export { getFundSectionName, currencyAbbr }
