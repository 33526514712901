import { get } from 'lodash'
import {
  fk,
} from 'redux-orm'
import { session } from '../schemas'
import { QurttyModel } from './modelDefault'

const getPhotoUrl = function (urlName, category, options = {}) {
  if (this.getPhotos()[0] && this.getPhotos()[0][urlName]) {
    return this.getPhotos()[0][urlName]
  }
}

const getPhones = function () {
  return this.getPolymorphicMany('V3_Phone', 'card').toModelArray()
}
const getEmails = function () {
  return this.getPolymorphicMany('V3_Email', 'card').toModelArray()
}
const getAddresses = function () {
  return this.getPolymorphicMany('V3_Address', 'card').toModelArray()
}
const getWebsites = function () {
  return this.getPolymorphicMany('V3_Website', 'card').toModelArray()
}

export class V3_Membership extends QurttyModel {
  static modelName = 'V3_Membership'
  modelUrl = 'card/memberships'

  static fields = {
    member_card_id: fk({ to: 'V3_MemberCard', as: 'member_card', relatedName: 'memberships' }),
    company_card_id: fk({ to: 'V3_CompanyCard', as: 'company_card', relatedName: 'memberships' }),
  }

  business_cards: any

  getBusinessCards () {
    return this.business_cards.toModelArray()
  }
}

export class V3_ProjectSingleInvitation extends QurttyModel {
  static modelName = 'V3_ProjectSingleInvitation'
  modelUrl = 'v3/project_single_invitations'

  static fields = {
    invitee_member_card_id: fk({ to: 'V3_MemberCard', as: 'invitee_member_card', relatedName: 'received_project_single_invitations' }),
  }
}

export class V3_MemberCard extends QurttyModel {
  static modelName = 'V3_MemberCard'
  static nameCombinations = [
    'firstname lastname', 'lastname middlename firstname', 'lastname (middlename) firstname', 'lastname firstname',
    'title firstname lastname', 'title lastname middlename firstname', 'title lastname (middlename) firstname', 'title lastname firstname',
    'firstname lastname title', 'lastname middlename firstname title', 'lastname (middlename) firstname title', 'lastname firstname title',
  ]

  modelUrl = 'member_cards'
  fallbackurl = 'https://my.qurtty.com/assets/themes/1/images/icon/noprofilephoto.png'
  photoablePhotoParticipationKeyName = 'member_card_photo_participations'

  static INCLUDES_PARAMS = {
    photo: {},
    phones: {},
    emails: {},
    addresses: { parsed_address: {} },
    websites: {},
    memberships: { },
    linked_values: {},
    requestabilities: {},
    particle_requests: {},
    extension_entity: { values: {} },
  }

  static fields = {
  }

  photo_ids: number[]
  memberships: any
  sealed_for_card_id: any
  received_friendships: any
  received_friendships_count: number
  sealed_for_card: this
  requested_friendships: any
  received_project_single_invitations: any

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
  getWebsites = getWebsites

  getPhotos () {
    return this.photo_ids.map((id) => session.V3_Photo.withId(id))
  }

  getExtensionEntity () {
    return this.getPolymorphicOne('V3_Eav_Entity', 'extended_particle')
  }

  getPhotoablePhotoParticipations () {
    return [
      ...this.getAssociation('member_card_in_member_photo_participations'),
      ...this.getAssociation('member_card_in_company_photo_participations'),
      ...this.getAssociation('member_card_in_project_photo_participations'),
      ...this.getAssociation('member_card_in_doc_photo_participations'),
    ]
  }

  printName (locale = 'en', combination = null) {
    const subj = this
    // no need to let subj to be card.member, coz correct firstname already shadowed in card
    let name = combination || get(this, `name_combination_${locale}`);
    ['title', 'firstname', 'middlename', 'lastname'].forEach((key) => {
      name = name.replace(key, subj[`${key}_${locale}`] || '')
    })
    return name.trim()
  }

  getBusinessCard () {
    const membership = this.memberships.toModelArray()[0]
    return membership ? membership.business_cards.toModelArray()[0] : null
  }

  getBusinessCards () {
    return this.memberships.toModelArray().flatMap((msp) => msp.getBusinessCards()).filter(Boolean)
  }

  getMembershipOf (companyCard) {
    return this.memberships.toModelArray().find((msp) => msp.company_card_id === (companyCard || {}).id)
  }

  getGangCard () {
    return this.memberships.toModelArray().map((c) => c.company_card)[0]
  }

  getGangCards () {
    return this.memberships.toModelArray().map((c) => c.company_card).filter(Boolean)
  }

  getPhotoUrl = getPhotoUrl

  fromMeStatus () {
    let card = this
    if (card.getAssociation('sealed_cards').length > 0) {
      card = card.getAssociation('sealed_cards')[0]
    }
    if (card.sealed_for_card_id) {
      // linked to a real card
      const friendships = card.received_friendships
      const friendship = friendships.first()
      if (friendship) {
        if (friendship.status === 'requesting') {
          return 'pending friendship accept'
        } else if (friendship.status === 'accepted') {
          return 'added as friend'
        } else {
          return 'unknown'
        }
      } else {
        return 'real'
      }
    } else {
      // not linked to a real card
      // when creating new request, received_friendships_count won't update from backend, so need to count by count()
      if (card.received_friendships_count > 0 || card.received_friendships.count() > 0) {
        return 'pending signup'
      } else {
        return 'virtual'
      }
    }
  }

  fromOtherStatus (myMemberId) {
    let card = this
    if (card.sealed_for_card) {
      card = card.sealed_for_card
    }
    // this should be a real card (others)
    const friendships = card.requested_friendships.filter({ cos_member_id: myMemberId })
    const friendship = friendships.first()
    if (friendship) {
      if (friendship.status === 'requesting') {
        return 'pending friendship accept'
      } else if (friendship.status === 'accepted') {
        return 'added as friend'
      } else {
        return 'unknown'
      }
    } else {
      return 'error'
    }
  }
}

export class V3_CompanyCard extends QurttyModel {
  static modelName = 'V3_CompanyCard'
  modelUrl = 'company_cards'
  fallbackurl = 'https://my.qurtty.com/assets/themes/1/images/icon/nocompany.png'
  photoablePhotoParticipationKeyName = 'company_card_photo_participations'

  static INCLUDES_PARAMS = {
    photo: {},
    phones: {},
    emails: {},
    addresses: { parsed_address: {} },
    websites: {},
    memberships: {},
    linked_values: {},
    requestabilities: {},
    particle_requests: {},
    extension_entity: { values: {} },
  }

  static fields = {
    _v5_company_id: fk({ to: 'V5_Company', as: '_v5_company', relatedName: '_v3_company_cards' }),
  }

  photo_ids: number[]
  memberships: any

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
  getWebsites = getWebsites

  getPhotos () {
    return this.photo_ids.map((id) => session.V3_Photo.withId(id))
  }

  getExtensionEntity () {
    return this.getPolymorphicOne('V3_Eav_Entity', 'extended_particle')
  }

  getIndustryExposures () {
    return this.getPolymorphicMany('V3_IndustryExposure', 'card').toModelArray()
  }

  getPhotoablePhotoParticipations () {
    return [
      ...this.getAssociation('company_card_in_company_photo_participations'),
      ...this.getAssociation('company_card_in_member_photo_participations'),
      ...this.getAssociation('company_card_in_project_photo_participations'),
      ...this.getAssociation('company_card_in_doc_photo_participations'),
    ]
  }

  getGangsterCards () {
    return this.memberships.toModelArray().map((c) => c.member_card).filter(Boolean)
  }

  printName (locale = 'en') {
    return this['fullname_' + locale]
  }

  getPhotoUrl = getPhotoUrl
}

export class V3_BusinessCard extends QurttyModel {
  static modelName = 'V3_BusinessCard'

  static fields = {
    v5_department_id: fk({ to: 'V5_Department', as: 'v5_department', relatedName: 'v3_business_cards' }),
    v5_staff_role_id: fk({ to: 'V5_StaffRole', as: 'v5_staff_role', relatedName: 'v3_business_cards' }),
    membership_id: fk({ to: 'V3_Membership', as: 'membership', relatedName: 'business_cards' }),
  }

  static INCLUDES_PARAMS = {
    phones: {},
    emails: {},
    addresses: { parsed_address: {} },
    websites: {},
  }

  getPhones = getPhones
  getEmails = getEmails
  getAddresses = getAddresses
  getWebsites = getWebsites
}

export class V3_Society extends QurttyModel {
  modelUrl = 'societies'

  static modelName = 'V3_Society'
  static fields = {
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'societies' }),
    parent_society_id: fk({ to: 'V3_Society', as: 'parent_society', relatedName: 'child_societies' }),
  }
}

export class V3_SocietyMemberCard extends QurttyModel {
  modelUrl = 'society_member_cards'

  static modelName = 'V3_SocietyMemberCard'
  static fields = {
    society_id: fk({ to: 'V3_Society', as: 'society', relatedName: 'society_member_cards' }),
    member_card_id: fk({ to: 'V3_MemberCard', as: 'member_card', relatedName: 'society_member_cards' }),
  }
}

export class V3_Shared_Industry extends QurttyModel {
  modelUrl = 'shared/industries'

  static modelName = 'V3_Shared_Industry'
  static fields = {
    parent_industry_id: fk({ to: 'V3_Shared_Industry', as: 'parent_industry', relatedName: 'child_industries' }),
  }

  parent_industry: any

  branch () {
    return [...(this.parent_industry ? this.parent_industry.branch() : []), this]
  }

  branchNames (lo = 'en') {
    return this.branch().map((indst) => indst[`name_${lo}`] || '')
  }
}

export class V3_IndustryExposure extends QurttyModel {
  modelUrl = 'industry_exposures'

  static modelName = 'V3_IndustryExposure'
  static fields = {
    shared_industry_id: fk({ to: 'V3_Shared_Industry', as: 'shared_industry', relatedName: 'industry_exposures' }),
  }
}

export const cardModelRegister = (orm) => {
  orm.register(
    V3_Membership,
    V3_MemberCard,
    V3_CompanyCard,
    V3_BusinessCard,
    V3_Society,
    V3_SocietyMemberCard,
    V3_Shared_Industry,
    V3_IndustryExposure,
    V3_ProjectSingleInvitation,
  )
}
