import axios from '../api/axiosSetting'
import cookiesHelper from '../helpers/cookiesHelper'

const validateToken = (params = {}) => {
  return axios.get('/auth/validate_token', { params, baseURL: process.env.REACT_APP_AUTH_BASE_URL })
}

const sendOtp = (params) => {
  return axios.post('members/send_otp', params, { baseURL: process.env.REACT_APP_AUTH_BASE_URL })
}
const sendSmsOtp = (params) => {
  return axios.post('members/send_sms_otp', params, { baseURL: process.env.REACT_APP_AUTH_BASE_URL })
}

const signInUser = (data = {}, config = {}) => {
  return axios.post('auth/sign_in', data, { baseURL: process.env.REACT_APP_AUTH_BASE_URL, ...config })
}

const signOut = () => {
  return deleteSession(getAuthSessionUuid()).then(() => {
    removeAuthToken()
  })
}

const fetchSessions = (params = {}) => {
  return axios.get('/auth/sessions', { params, baseURL: process.env.REACT_APP_AUTH_BASE_URL })
}

const deleteSession = (sessionUuid) => {
  return axios.post('/auth/sign_out_sessions', { session_uuid: sessionUuid }, { baseURL: process.env.REACT_APP_AUTH_BASE_URL })
}

const updateCookiesFromResponse = (resp) => {
  let authorization = null
  let sessionUuid = null
  if (resp.headers instanceof Function) {
    // angular-restmod
    authorization = resp.headers().authorization
    sessionUuid = resp.headers()['auth-session-uuid']
  } else if (resp.headers.get) {
    authorization = resp.headers.get('authorization')
    sessionUuid = resp.headers.get('auth-session-uuid')
  } else {
    authorization = resp.headers.authorization
    sessionUuid = resp.headers['auth-session-uuid']
  }

  setAuthToken({ authorization, 'auth-session-uuid': sessionUuid })
}

const setAuthToken = (headers) => {
  if (!headers.authorization || !headers['auth-session-uuid']) { return }
  if (headers.authorization === 'undefined' || headers['auth-session-uuid'] === 'undefined') { return }

  const recievedJwtToken = headers.authorization || ''
  cookiesHelper.set(getTokenKey(), recievedJwtToken.replace('Bearer ', ''), { expires: 365 })
  cookiesHelper.set(getSessionUuidKey(), headers['auth-session-uuid'], { expires: 365 })
}

const getAuthHeaders = () => {
  return { authorization: `Bearer ${getAuthToken()}`, 'auth-session-uuid': getAuthSessionUuid() }
}

const getAuthToken = () => {
  return cookiesHelper.get(getTokenKey())
}

const getAuthSessionUuid = () => {
  return cookiesHelper.get(getSessionUuidKey())
}

const removeAuthToken = () => {
  return cookiesHelper.remove(getTokenKey())
}

const getTokenKey = () => {
  let prefix = null
  if (window.location.hostname === 'localhost' || window.location.hostname.endsWith('.local')) {
    prefix = 'development'
  } else if (window.location.hostname.startsWith('staging-')) {
    prefix = 'staging'
  } else {
    prefix = 'uat'
  }
  return `${prefix}_token`
}
const getSessionUuidKey = () => {
  let prefix = null
  if (window.location.hostname === 'localhost' || window.location.hostname.endsWith('.local')) {
    prefix = 'development'
  } else if (window.location.hostname.startsWith('staging-')) {
    prefix = 'staging'
  } else {
    prefix = 'uat'
  }
  return `${prefix}_session_uuid`
}

export default {
  validateToken,
  sendOtp,
  sendSmsOtp,
  signInUser,
  signOut,
  fetchSessions,
  deleteSession,
  getTokenKey,
  setAuthToken,
  getAuthToken,
  getAuthSessionUuid,
  getSessionUuidKey,
  getAuthHeaders,
  removeAuthToken,
  updateCookiesFromResponse,
}
