import {
  fk,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_Friendship extends QurttyModel {
  static modelName = 'V3_Friendship'
  static fields = {
    sin_member_id: fk({ to: 'Member', as: 'sin_member', relatedName: 'requested_friendships' }),
    sin_member_card_id: fk({ to: 'V3_MemberCard', as: 'sin_member_card', relatedName: 'requested_friendships' }),
    cos_member_id: fk({ to: 'Member', as: 'cos_member', relatedName: 'received_friendships' }),
    cos_member_card_id: fk({ to: 'V3_MemberCard', as: 'cos_member_card', relatedName: 'received_friendships' }),
  }
}

export const friendModelRegister = (orm) => {
  orm.register(V3_Friendship)
}
