const sectionGroups: any = {
  'Risk Rating': [
    ['risk-rating', ['', '']],
  ],
  Info: [
    ['fund-currency', { en: 'Investment-linked Fund Currency', zh_hk: '投資相連基金貨幣' }],
    ['management-charge', { en: 'The Investment-linked Fund Management Charge', zh_hk: '投資相連基金的管理費' }],
    ['launch-date', { en: 'Launch Date', zh_hk: '成立日期' }],
    ['since-launch', { en: 'Since Launch', zh_hk: '成立日期' }],
    ['fund-manager', { en: 'Underlying Fund Manager', zh_hk: '相關基金經理' }],
    ['management-tee', { en: 'Management Tee', zh_hk: '管理費' }],
    ['fund-size', { en: 'Underlying Fund Size', zh_hk: '相關基金資產' }],
    ['net-asset-value', { en: 'Net Asset Value per unit', zh_hk: '單位資產淨值' }],
    ['net-asset-value-2', { en: 'Net Asset Value', zh_hk: '單位資產淨值' }],
    ['fund-detail', { en: 'The Underlying Fund of Investment-linked Fund Details', zh_hk: '投資相連基金的相關基金資料' }],
    ['keyfacts', { en: 'Key Facts', zh_hk: '重要資料' }],
    ['unit', { en: 'unit', zh_hk: '單元' }],
    ['currency', { en: 'currency', zh_hk: '貨幣' }],
    ['isin-code', { en: 'ISIN CODE', zh_hk: 'ISIN 代碼' }],
    ['bloomberg-code', { en: 'BLOOMBERG CODE', zh_hk: 'BLOOMBERG 代碼' }],
    ['risk-level', { en: 'Risk Level', zh_hk: '風險等級' }],
  ],
  Objective: [
    ['objective', { en: 'The Investment Objective of the Underlying Fund of Investment-linked Fund:', zh_hk: '投資相連基金的相關基金目標 :' }],
    ['investment-objective', { en: 'Investment Objective', zh_hk: '投資目標' }],
  ],
  Performance: [
    ['performance', { en: 'The Underlying Fund of Investment-linked Fund Performance', zh_hk: '投資相連基金的相關基金表現' }],
    ['cumulative-performance', { en: 'Cumulative Performance', zh_hk: '累積表現' }],
  ],
  'Top Holdings': [
    ['breakdown-by-company', { en: 'Top 10 Holdings', zh_hk: '持股最多之公司' }],
  ],
  Allocation: [
    ['breakdown-by-geo', { en: 'Geographical Allocation', zh_hk: '地區分佈' }],
    ['breakdown-by-sector', { en: 'Sector Allocation', zh_hk: '行業分佈' }],
    ['breakdown-by-rating', { en: 'Credit Rating (S&P / Moody) Allocation', zh_hk: '信用評級（標準普爾／穆迪）分佈' }],
    ['breakdown-by-asset', { en: 'Asset Allocation', zh_hk: '資產分佈' }],
    ['breakdown-by-currency', { en: 'Currency Allocation', zh_hk: '貨幣分佈' }],
  ],
  Footnote: [
    ['footnote', { en: '', zh_hk: '' }],
  ],
  Fake: [
    ['fake-objective-content', { en: '(Investment Objective content)', zh_hk: '(基金目標內文)' }],
    ['fake-performance-fund-title', { en: '(Fund Performance title)', zh_hk: '(基金表現名稱)' }],
    ['fake-performance-benchmark-title', { en: '(Benchmark Performance title)', zh_hk: '(市場指標名稱)' }],
    ['fake-fund-name', { en: '(Fund name)', zh_hk: '(基金名稱)' }],
  ],
}

export default sectionGroups
