import {
  fk,
} from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_Photo extends QurttyModel {
  static modelName = 'V3_Photo'
  static fields = {
  }

  static INCLUDES_PARAMS = {
    photo_participations: {},
    banner_block_photos: {},
    generic_block_slides: {},
    backgrounded_product_segments: {},
    backgrounded_product_banner_blocks: {},
    backgrounded_product_html_blocks: {},
    backgrounded_product_generic_blocks: {},
    backgrounded_product_book_blocks: {},
    covered_projects: {},
    faviconed_website_controls: {},
    header_logoed_website_controls: {},
    footer_logoed_website_controls: {},
    backgrounded_website_controls: {},
    backgrounded_webpages: {},
    backgrounded_webpage_sections: {},
    seo_settings: {},
    linked_values: {},
    particle_requests: {},
    external_pages: {},
    extension_entity: { values: {} },
  }

  icon_url: any

  getExternalPages () {
    return this.getPolymorphicMany('V3_ExternalPage', 'holder').toModelArray()
  }

  getExtensionEntity () {
    return this.getPolymorphicOne('V3_Eav_Entity', 'extended_particle')
  }

  getUrl (urlName) {
    return this[urlName] || this.icon_url
  }
}

export class V3_PhotoFrame extends QurttyModel {
  static modelName = 'V3_PhotoFrame'
  static fields = {
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'photo_frames' }),
  }
}

export class V3_Album extends QurttyModel {
  static modelName = 'V3_Album'
  static fields = {
    member_id: fk({ to: 'Member', as: 'member', relatedName: 'albums' }),
  }
}

export class V3_AlbumPhoto extends QurttyModel {
  static modelName = 'V3_AlbumPhoto'
  static fields = {
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'album_photos' }),
    album_id: fk({ to: 'V3_Album', as: 'album', relatedName: 'album_photos' }),
  }
}

export class V3_PhotoParticipation extends QurttyModel {
  static modelName = 'V3_PhotoParticipation'
  static fields = {
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'photo_participations' }),
  }
}

export class V3_PhotoablePhotoParticipation extends QurttyModel {
  static modelName = 'V3_PhotoablePhotoParticipation'
  static fields = {
    photo_participation_id: fk({ to: 'V3_PhotoParticipation', as: 'photo_participation', relatedName: 'photoable_photo_participations' }),
  }
}
export class V3_CardParticipation extends QurttyModel {
  static modelName = 'V3_CardParticipation'
  static fields = {}
}

export class V3_ExternalPage extends QurttyModel {
  static modelName = 'V3_ExternalPage'
  static fields = {
  }
}

export const photoModelRegister = (orm) => {
  orm.register(
    V3_Album, V3_AlbumPhoto, V3_PhotoParticipation,
    V3_PhotoablePhotoParticipation,
    V3_Photo,
    V3_PhotoFrame,
    V3_CardParticipation,
    V3_ExternalPage,
  )
}
