import _, { cloneDeep, get } from 'lodash'
import { utils } from '..'
import { modelAssociationRefreshAPI, modelCreateAPI, modelDeleteAPI, modelRefreshAPI, modelSavingAPI } from '../api/api'
import * as api from '../api/api'
import * as apiHelper from '../api/apiHelper'
import { associationNameLookupList, modelClassToModelNameLookupList, modelNamePolymorphicLookupList } from './lookupList'
import { session } from './schemas'
// Static helper function
// Photo helper function
const recursiveFormData = (obj, form, namespace = null) => {
  const formData = form
  let formKey

  for (const property in obj) {
    if (property in obj) {
      if (namespace) {
        formKey = `${namespace}[${property}]`
      } else {
        formKey = property
      }

      if (typeof obj[property] === 'object') {
        recursiveFormData(obj[property], formData, formKey)
      } else {
        formData.append(formKey, obj[property])
      }
    }
  }
  return formData
}

const photoUpload = (url, file, param) => {
  const formData = new FormData()
  // console.log(file);
  formData.append('file', file)
  recursiveFormData(param, formData)
  // console.log(formData);

  return api.uploadPhoto(url, formData).then((resp) => {
    insertNestedResponseToOrm(resp, session)
    return resp
  })
}

const recursiveFiles = (url, fileList, param) => {
  return new Promise((resolve) => {
    if (fileList.length > 0) {
      const file = fileList.pop()
      return photoUpload(url, file, param).then((resp) => {
        return recursiveFiles(url, fileList, param).then((subResp) => {
          const response = subResp
          response.success.push(session.V3_Photo.withId(resp.data.photo.id))
          // console.log(response.success);
          resolve(response)
        })
      }, (resp) => {
        return recursiveFiles(url, fileList, param).then((subResp) => {
          // console.log(file);
          const response = subResp
          response.fail.push({ fileName: file.name, size: file.size })
          resolve(response)
        })
      })
    } else {
      return resolve({ success: [], fail: [] })
    }
  })
}
// Photo helper function End

// Export Function

export const organizeParam = (model, argument) => {
  const param = {}
  if (argument.length > 0) {
    const args = Array.from(argument)
    args.forEach((text: any) => {
      param[text] = model[text]
    })
  } else {
    const keyList = Object.keys(model).filter((text) => text[0] !== '_' && text[0] !== '$' && text !== 'id')
    keyList.forEach((text) => {
      param[text] = model[text]
    })
  }
  return param
}

/**
 * convert resources URL like 'member_cards' to model name like 'MemberCard'
 * @param  {String} resourcesUrl resources URL
 * @return {String}              model name
 */

export const associationNameToResourcesUrl = (associationName) => {
  const resourcesUrl = associationNameLookupList[associationName] || associationName
  return resourcesUrl
}

export const modelClassToModelName = (modelClass) => {
  if (!modelClass) { return }
  const name = modelClassToModelNameLookupList[modelClass]
  return name || modelClass.replace(/::/g, '_')
}
export const modelNameToPolymorphicNames = (modelName) => {
  const names = modelNamePolymorphicLookupList[modelName] || []
  return names
}

export const insertNestedResponseToOrm = (nestedResponse, session) => {
  function handleObj (obj, key, parentObjKeyPairs = []) {
    // inner to outer
    const val = obj[key]
    if (!val || (!Array.isArray(val) && !val.model_class)) { return }

    if (Array.isArray(val)) {
      if (val[0] && val[0].id && val[0].model_class && session[modelClassToModelName(val[0].model_class)]) {
        if (key.startsWith('_') || key.startsWith('m2m_') || key.startsWith('hand4_')) { obj[`${key}_ids`] = val.map((rec) => rec.id) }
        delete obj[key]
      }
    } else {
      const modelName = modelClassToModelName(val.model_class)
      if (session[modelName] && val.id) {
        try {
          const nestedPath = parentObjKeyPairs.map((pair) => pair[1]).join('.') + `.${key}`
          if (key.startsWith('_') || key.startsWith('m2m_') || key.startsWith('hand4_')) {
            obj[`${key}_id`] = val.id
          }
          if (!Array.isArray(obj)) { delete obj[key] } // to prevent company.m2m_company_card_alias_industry_exposures becoming [empty * 74]
          session[modelName].upsert({ ...cloneDeep(val), $rawResponseData: get({ main: nestedResponse }, nestedPath) })
        } catch (e) {
          console.error('failed to upsert: ', modelName, val, e)
        }
      } else {
        console.warn('ORM model not defined: ', val.model_class)
      }
    }
  }
  const clonedNestedResponse = cloneDeep(nestedResponse)
  utils.traverse({ main: clonedNestedResponse }, handleObj)
}

export const removeRecordFromOrm = (record) => {
  const modelName = modelClassToModelName(record.model_class)
  const ormRecord = modelName && session[modelName] ? session[modelName].withId(record.id) : null
  if (ormRecord) {
    ormRecord.delete()
  } else {
    console.warn('fail to delete: ', record.model_class)
  }
}

export const modelPhotoUpload = (url, fileList, param) => {
  if (typeof fileList === 'object') {
    return recursiveFiles(url, fileList, param)
  } else {
    return photoUpload(url, fileList, param)
  }
}

export const modelRefresh = (url, record, param, theSession = session) => {
  const modelName = modelClassToModelName(record.model_class)
  return modelRefreshAPI(url, record.id, param).then((json) => {
    insertNestedResponseToOrm(json, theSession)
    const id = (Object.values(json)[0] as any).id
    return theSession[modelName].withId(id)
  })
}

export const modelAssociationRefresh = (url, associationName, record, param, theSession = session) => {
  const newUrl = `${url}/${record.id}/${associationName}`
  return modelAssociationRefreshAPI(newUrl, param).then((json) => {
    insertNestedResponseToOrm(json, theSession)
    const key = Object.keys(json)[0]
    const records = json[key] || []
    const ids = records.map((rec) => rec.id)
    const associationModelName = modelClassToModelName(records[0]?.model_class)
    if (!associationModelName) { return [] }
    return theSession[associationModelName].filter((rec) => ids.includes(rec.id))
  })
}

export const doubleModelAssociationRefresh = (url, associationName, record, param) => {
  const newUrl = `${url}/${record.id}/${associationName}`
  return apiHelper.doubleAPIChecking(newUrl, param).then((resp: any) => {
    return resp.particleList
  })
}

export const modelCreate = (associationName, record, param, theSession = session) => {
  const url = `${record.modelUrl}/${record.id}/${associationName}`
  return modelCreateAPI(url, param).then((json) => {
    insertNestedResponseToOrm(json, theSession)
    const key = Object.keys(json)[0]
    const record = json[key]
    const id = record?.id
    const modelName = modelClassToModelName(record?.model_class)
    if (!modelName) { return }
    return theSession[modelName].withId(id)
  })
}

export const modelSaving = (url, id, record, theSession = session) => function (...args) {
  let param = {}
  const isDirectSave = args.length > 0 && typeof args[0] === 'object'
  if (isDirectSave) {
    param = args[0]
  } else {
    param = organizeParam(record, args)
  }
  return modelSavingAPI(url, id, param).then((json) => {
    if (isDirectSave) {
      try {
        record.update(param)
      } catch (e) { }
    }

    if (!record.model_class) {
      console.log('!! no model_class for: ', record)
    }
    const modelName = modelClassToModelName(record.model_class)
    if (typeof (json) === 'object') {
      insertNestedResponseToOrm(json, theSession)
    } else {
      // 204 empty string returned
    }

    return theSession[modelName].withId(id)
  })
}

export const modelSavingChanges = (url, id, record) => function (...args) {
  let params = {}
  const isDirectSave = args.length > 0 && typeof args[0] === 'object'
  if (isDirectSave) {
    params = args[0]
  } else {
    if (args.length > 0) {
      params = organizeParam(record, args)
    } else {
      params = record.$_changes
    }
  }
  params = Object.keys(params).filter((key) => {
    return (key in record.$_changes) && record.$_changes[key] !== record[key]
  }).reduce((sum, key) => {
    sum[key] = record.$_changes[key]
    return sum
  }, {})
  if (Object.keys(params).length === 0) {
    return Promise.resolve(record)
  } else {
    return modelSavingAPI(url, id, params).then((json) => {
      if (!isDirectSave) {
        try {
          record.update(params)
        } catch (e) { }
      }
      return json
    })
  }
}

export const modelDelete = (url, id, record, theSession = session) => function () {
  return modelDeleteAPI(url, id).then(() => {
    // to delete all inherited parents in orm
    const modelName = modelClassToModelName(record.model_class)
    const names = [modelName].concat(modelNameToPolymorphicNames(modelName))
    console.log('deleting in models: ', names)
    for (const name of names) {
      const clazz = theSession[name]
      if (clazz) {
        const rec = clazz.withId(id)
        if (rec) {
          rec.delete()
        }
      }
    }
  })
}

/**
 * http GET particles(photos/cards/products/entities) of specified entities
 * @param  {Array} entities     specified entities
 * @param  {Project/Doc} host         the environment that particles live in
 * @param  {Object} [options={}] should be default to:
 * {fetchLinked: {
        Photo: "all", （available strategies: 'all', 'first-val-only', 'skip'）
        "V3::MemberCard": "all",
        "V3::CompanyCard": "all",
        "V3::Product": "all",
        "V3::Eav::Entity": "all"
      }
    }

 * @return {null}              [description]
 */
export const $fetchLinkedParticles = (entities, host, options: any = {}) => {
  options.fetchLinked = options.fetchLinked || {}
  options.params = options.params || {}

  const isStructureAttr = (attr) => attr.type_type === 'V3::Eav::Structure' && attr.type()
  const allAttrs = _.chain(entities)
    .filter((ett) => !!ett.structure)
    .flatMap((ett) => ett.structure.getAssociation('attrs'))
    .uniqBy((attr) => attr.id)
    .value()

  const roomAttrs = _.filter(allAttrs, isStructureAttr)
  let typeStructureEntities = []

  typeStructureEntities = roomAttrs.reduce((sum, attr) => {
    const values = _.flatMap(entities, (ett) => _.filter(ett.getAssociation('values'), (val) => val.attr_id === attr.id))
    const ids = _.map(values, (val) => val.content_particle_id)
    const existingRow = _.find(sum, (row) => row.structure.id === attr.type().id)
    if (existingRow) {
      existingRow.entityIds.push(...ids)
    } else {
      sum.push({
        structure: attr.type(),
        entityIds: ids,
      })
    }
    return sum
  }, [])

  return new Promise((resolve) => {
    const promises = []

    promises.push(...typeStructureEntities.map((row) => {
      return row.structure.$refreshAssociation('entities', {
        ...options.params,
        id: row.entityIds,
      })
    }))

    Promise.all(promises).then(() => resolve(null))
  })
}
