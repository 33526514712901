import React from 'react'
import FactsheetTemplate1 from './templates/FactsheetTemplate1'
import FactsheetTemplate2 from './templates/FactsheetTemplate2'

interface FactsheetPreviewProps {
  factsheets: any,
  template: number,
  lang: string
}

function FactsheetPreview ({ factsheets, template, lang }: FactsheetPreviewProps) {
  const getTemplate = () => {
    switch (template) {
      case 1:
        return <FactsheetTemplate1 factsheets={factsheets} />
      case 2:
        return <FactsheetTemplate2 factsheets={factsheets} lang={lang} />
      default:
        break
    }
  }

  return getTemplate()
}

export default FactsheetPreview
