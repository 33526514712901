import { fk } from 'redux-orm'
import { QurttyModel } from './modelDefault'

export class V3_Quiz_QuizzerAvatar extends QurttyModel {
  static modelName = 'V3_Quiz_QuizzerAvatar'
  modelUrl = 'v3/quiz/quizzer_avatars'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'quiz_quizzer_avatars' }),
  }

  image_attachment: any

  getUrl () {
    return this.image_attachment.blob.url
  }
}

export class V3_Quiz_Quizzer extends QurttyModel {
  static modelName = 'V3_Quiz_Quizzer'
  modelUrl = 'v3/quiz/quizzers'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'quiz_quizzers' }),
    quizzer_avatar_id: fk({ to: 'V3_Quiz_QuizzerAvatar', as: 'quizzer_avatar', relatedName: 'quizzers' }),
  }

  static INCLUDES_PARAMS = { quizzer_avatar: { image_attachment: { blob: {} } } }
}

export class V3_Quiz_Topic extends QurttyModel {
  static modelName = 'V3_Quiz_Topic'
  modelUrl = 'v3/quiz/topics'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'quiz_topics' }),
  }
}

export class V3_Quiz_Subtopic extends QurttyModel {
  static modelName = 'V3_Quiz_Subtopic'
  modelUrl = 'v3/quiz/subtopics'
  static fields = {
    topic_id: fk({ to: 'V3_Quiz_Topic', as: 'topic', relatedName: 'subtopics' }),
  }
}

export class V3_Quiz_StringArrayQuestion extends QurttyModel {
  static modelName = 'V3_Quiz_StringArrayQuestion'
  modelUrl = 'v3/quiz/string_array_questions'
  static fields = {
    topic_id: fk({ to: 'V3_Quiz_Topic', as: 'topic', relatedName: 'string_array_questions' }),
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'quiz_string_array_questions' }),
  }

  getSubtopicTaggings () {
    return this.getPolymorphicMany('V3_Quiz_SubtopicTagging', 'question').toModelArray()
  }

  getQuestionArrangings () {
    return this.getPolymorphicMany('V3::Quiz::QuestionArranging', 'question').toModelArray()
  }

  getTakingsQuantity () {
    return this.getQuestionArrangings().reduce((sum, tmpQuestionArranging) => sum + tmpQuestionArranging.paper.takings.toModelArray().length, 0)
  }

  getAnswersQuantity () {
    return this.getQuestionArrangings().reduce((sum, tmpQuestionArranging) => sum + tmpQuestionArranging.getAnswers().length, 0)
  }
}

export class V3_Quiz_StringQuestion extends QurttyModel {
  static modelName = 'V3_Quiz_StringQuestion'
  modelUrl = 'v3/quiz/string_questions'
  static fields = {
    topic_id: fk({ to: 'V3_Quiz_Topic', as: 'topic', relatedName: 'string_questions' }),
    photo_id: fk({ to: 'V3_Photo', as: 'photo', relatedName: 'quiz_string_questions' }),
  }

  getSubtopicTaggings () {
    return this.getPolymorphicMany('V3_Quiz_SubtopicTagging', 'question').toModelArray()
  }

  getQuestionArrangings () {
    return this.getPolymorphicMany('V3::Quiz::QuestionArranging', 'question').toModelArray()
  }

  getTakingsQuantity () {
    return this.getQuestionArrangings().reduce((sum, tmpQuestionArranging) => sum + tmpQuestionArranging.paper.takings.toModelArray().length, 0)
  }

  getAnswersQuantity () {
    return this.getQuestionArrangings().reduce((sum, tmpQuestionArranging) => sum + tmpQuestionArranging.getAnswers().length, 0)
  }
}

export class V3_Quiz_SubtopicTagging extends QurttyModel {
  static modelName = 'V3_Quiz_SubtopicTagging'
  modelUrl = 'v3/quiz/subtopic_taggings'
  static fields = {
    subtopic_id: fk({ to: 'V3_Quiz_Subtopic', as: 'subtopic', relatedName: 'subtopic_taggings' }),
  }

  getQuestion () {
    return this.getPolymorphicHolder('question')
  }
}

export class V3_Quiz_Paper extends QurttyModel {
  static modelName = 'V3_Quiz_Paper'
  modelUrl = 'v3/quiz/papers'
  static fields = {
    project_id: fk({ to: 'V3_Project', as: 'project', relatedName: 'quiz_papers' }),
  }
}

export class V3_Quiz_QuestionArranging extends QurttyModel {
  static modelName = 'V3_Quiz_QuestionArranging'
  modelUrl = 'v3/quiz/question_arrangings'
  static fields = {
    paper_id: fk({ to: 'V3_Quiz_Paper', as: 'paper', relatedName: 'question_arrangings' }),
  }

  question_type: string
  string_answers: any
  string_array_answers: any

  getQuestion () {
    return this.getPolymorphicHolder('question')
  }

  getAnswers () {
    switch (this.question_type) {
      case 'V3::Quiz::StringQuestion':
        return this.string_answers.toModelArray()
      case 'V3::Quiz::StringArrayQuestion':
        return this.string_array_answers.toModelArray()
      default:
        return []
    }
  }

  getTakingAnswer (takingId) {
    return this.getAnswers().find((ansr) => ansr.taking_id === takingId)
  }

  isTakingAnswerCorrect (takingId) {
    const answer = this.getTakingAnswer(takingId)
    return answer && answer.isCorrect()
  }
}

export class V3_Quiz_Taking extends QurttyModel {
  static modelName = 'V3_Quiz_Taking'
  modelUrl = 'v3/quiz/takings'
  static fields = {
    paper_id: fk({ to: 'V3_Quiz_Paper', as: 'paper', relatedName: 'takings' }),
    quizzer_id: fk({ to: 'V3_Quiz_Quizzer', as: 'quizzer', relatedName: 'takings' }),
  }

  paper: any

  getAnswers () {
    const questionArrangings = this.paper.question_arrangings.toModelArray()
    return questionArrangings.map((tmpQuestionArranging) => {
      return tmpQuestionArranging.string_array_answers.filter({ taking_id: this.id }).toModelArray()[0] || tmpQuestionArranging.string_answers.filter({ taking_id: this.id }).toModelArray()[0]
    }).filter(Boolean)
  }
}

export class V3_Quiz_StringAnswer extends QurttyModel {
  static modelName = 'V3_Quiz_StringAnswer'
  static fields = {
    taking_id: fk({ to: 'V3_Quiz_Taking', as: 'taking', relatedName: 'string_answers' }),
    question_arranging_id: fk({ to: 'V3_Quiz_QuestionArranging', as: 'question_arranging', relatedName: 'string_answers' }),
  }

  content: string
  question_arranging: any

  isCorrect () {
    return this.content === this.question_arranging.getQuestion().correct_answer_content
  }
}

export class V3_Quiz_StringArrayAnswer extends QurttyModel {
  static modelName = 'V3_Quiz_StringArrayAnswer'
  static fields = {
    taking_id: fk({ to: 'V3_Quiz_Taking', as: 'taking', relatedName: 'string_array_answers' }),
    question_arranging_id: fk({ to: 'V3_Quiz_QuestionArranging', as: 'question_arranging', relatedName: 'string_array_answers' }),
  }

  contents: string[]
  question_arranging: any

  isCorrect () {
    return this.contents.slice().sort().toString() === this.question_arranging.getQuestion().correct_answer_contents.slice().sort().toString()
  }
}

export const quizModelRegister = (orm) => {
  orm.register(
    V3_Quiz_QuizzerAvatar,
    V3_Quiz_Quizzer,
    V3_Quiz_Topic,
    V3_Quiz_Subtopic,
    V3_Quiz_StringQuestion,
    V3_Quiz_StringArrayQuestion,
    V3_Quiz_SubtopicTagging,
    V3_Quiz_Paper,
    V3_Quiz_QuestionArranging,
    V3_Quiz_Taking,
    V3_Quiz_StringAnswer,
    V3_Quiz_StringArrayAnswer,
  )
}
